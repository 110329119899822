import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import {todayTitle} from './SaveToDatabase';
import saveAs from 'file-saver';
import {LogoImg,textToBase64Barcode} from './Helper';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';
import {Button,Input, Header,Radio,Form,Grid,Segment,Label} from 'semantic-ui-react';
import { ref} from '../../firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';
var JsBarcode = require('jsbarcode');

function FloatDiv(arg1, arg2)
{
  var t1 = 0, t2 = 0, r1, r2;
  try { t1 = arg1.toString().split(".")[1].length } catch (e) { }
  try { t2 = arg2.toString().split(".")[1].length } catch (e) { }
  
    r1 =Number(arg1.toString().replace(".", ""))
    r2 =Number(arg2.toString().replace(".", ""))
    return (r1 / r2) * Math.pow(10, t2 - t1); 
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //這個每個客人都要改
      'customerNumber':'DEC',
      'SGPO':[],
      'finalDeliveryNumber':'',
      'cumulatedDeliveryNumber':'',
      'todayTitle':'',
      'nation':'',
      'deliveryNation':'HK',
    };
   
    this.ExportSGFile=this.ExportSGFile.bind(this);
    this.ExportMYFile=this.ExportMYFile.bind(this);
    this.ExportMOFile=this.ExportMOFile.bind(this);
    this.ExportHKFile=this.ExportHKFile.bind(this);
    this.ExportJPFile=this.ExportJPFile.bind(this);
    this.ExportAUFile=this.ExportAUFile.bind(this);
    this.ExportJPInvoice=this.ExportJPInvoice.bind(this);
    this.ExportJPLabelA4=this.ExportJPLabelA4.bind(this);

    this.handleInputChange=this.handleInputChange.bind(this);
    this.addToDatabase=this.addToDatabase.bind(this);
    this.handleTimeChange=this.handleTimeChange.bind(this);
    this.changeDeliveryNation=this.changeDeliveryNation.bind(this)
    this.ExportDeleteRecord=this.ExportDeleteRecord.bind(this)
    this.itemCalculationSGFile=this.itemCalculationSGFile.bind(this)
    this.itemCalculationMYFile=this.itemCalculationMYFile.bind(this)
    this.itemCalculationMOFile=this.itemCalculationMOFile.bind(this)
    this.itemCalculationHKFile=this.itemCalculationHKFile.bind(this)
    this.itemCalculationJPFile=this.itemCalculationJPFile.bind(this)
    this.itemCalculationAUFile = this.itemCalculationAUFile.bind(this)
    this.UniversalImportExcel=this.UniversalImportExcel.bind(this)
    this.handleUniversalLablePrint=this.handleUniversalLablePrint.bind(this)
   

  }

  componentDidMount (){
    //var userid = firebaseAuth.currentUser.uid;
    let today=todayTitle()[0];
    let year=todayTitle()[1];
    let month=todayTitle()[2];
    let day=todayTitle()[3];
    this.setState({
      'todayTitle':today,
      'year':year,
      'month':month,
      'day':day
    })
    
    
    
    ref.child('/infos/customers/JPDeliveryNumberData').on('value', (snapshot)=> {
            if(snapshot.val()==null){
              this.setState({
                'JPDeliveryNumber':0,
              })
                  
            }else{
                var data=snapshot.val()
                function measureSize(obj) {
                  var size = 0, key;
                  for (key in obj) {
                      if (obj.hasOwnProperty(key)) size++;
                  }
                  return size;
                };
                var size=measureSize(data)
                //這裡是把資料庫還有沒發的快遞單號數量算出來，給錢台一個顯示
                this.setState({
                  'JPDeliveryNumber':size,
                })
                

                
            }

    })
   
  }



  //下面幾個程式的this.state.year都被處理過了，不是今天的，是客人輸入的
  itemCalculationSGFile(){
    ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/SG').on('value', (snapshot)=> {
    
    let POInfo=snapshot.val();
    if(POInfo){
      let POKey=Object.keys(POInfo)[0];
      let PO=POInfo[POKey]
      
      let POs = [];
      let content=[];
      
      let title=[
          "快遞單號",                            //A
          "品項名",                              //B
          "數量",                                //C
          "報關金額",
         
        ];

       POs.push(title);

       
      
       Object.keys(PO).forEach(function(key) {
             var po = PO[key];
             Object.keys(po).forEach(function(itemname) {
                  let poArray = [
                    key,                                      //A
                    itemname,                                 //B
                    po[itemname].Number,                      //C
                    po[itemname].Amount,                      //D      
                  ]
                  POs.push(poArray);

                  if(content[itemname]){
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }else{
                    content[itemname]={}
                    content[itemname]['Number']=[]
                    content[itemname]['Amount']=[]
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }
                  
             })

     });

     function SumDatareduce(arr){
      return arr.reduce((a,b)=>a+b);  
     }

     Object.keys(content).forEach(function(key) {
            let Array = [
              '總數',
              key,                                      
              SumDatareduce(content[key].Number),
              SumDatareduce(content[key].Amount),                            
            ]
            POs.push(Array);
     })


      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day+'-'+this.state.customerNumber}-SG內容物清單.xlsx`);
      
    
    }
    })
  }
  
  itemCalculationMYFile(){
    ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/MY').on('value', (snapshot)=> {
    
    let POInfo=snapshot.val();
    if(POInfo){
      let POKey=Object.keys(POInfo)[0];
      let PO=POInfo[POKey]
      
      let POs = [];
      let content=[];
      
      let title=[
          "快遞單號",                            //A
          "品項名",                              //B
          "數量",                                //C
          "報關金額",
         
        ];

       POs.push(title);

       
      
       Object.keys(PO).forEach(function(key) {
             var po = PO[key];
             Object.keys(po).forEach(function(itemname) {
                  let poArray = [
                    key,                                      //A
                    itemname,                                 //B
                    po[itemname].Number,                      //C
                    po[itemname].Amount,                      //D      
                  ]
                  POs.push(poArray);

                  if(content[itemname]){
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }else{
                    content[itemname]={}
                    content[itemname]['Number']=[]
                    content[itemname]['Amount']=[]
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }
                  
             })

     });

     function SumDatareduce(arr){
      return arr.reduce((a,b)=>a+b);  
     }

     Object.keys(content).forEach(function(key) {
            let Array = [
              '總數',
              key,                                      
              SumDatareduce(content[key].Number),
              SumDatareduce(content[key].Amount),                            
            ]
            POs.push(Array);
     })


      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day+'-'+this.state.customerNumber}-MY內容物清單.xlsx`);
      
    
    }
    })
  }

  itemCalculationMOFile(){
    ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/MO').on('value', (snapshot)=> {
    
    let POInfo=snapshot.val();
    if(POInfo){
      let POKey=Object.keys(POInfo)[0];
      let PO=POInfo[POKey]
      
      let POs = [];
      let content=[];
      
      let title=[
          "快遞單號",                            //A
          "品項名",                              //B
          "數量",                                //C
          "報關金額",
         
        ];

       POs.push(title);

       
      
       Object.keys(PO).forEach(function(key) {
             var po = PO[key];
             Object.keys(po).forEach(function(itemname) {
                  let poArray = [
                    key,                                      //A
                    itemname,                                 //B
                    po[itemname].Number,                      //C
                    po[itemname].Amount,                      //D      
                  ]
                  POs.push(poArray);

                  if(content[itemname]){
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }else{
                    content[itemname]={}
                    content[itemname]['Number']=[]
                    content[itemname]['Amount']=[]
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }
                  
             })

     });

     function SumDatareduce(arr){
      return arr.reduce((a,b)=>a+b);  
     }

     Object.keys(content).forEach(function(key) {
            let Array = [
              '總數',
              key,                                      
              SumDatareduce(content[key].Number),
              SumDatareduce(content[key].Amount),                            
            ]
            POs.push(Array);
     })


      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day+'-'+this.state.customerNumber}-MO內容物清單.xlsx`);
      
    
    }
    })
  }
 
  itemCalculationHKFile(){
    ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/HK').on('value', (snapshot)=> {
    
    let POInfo=snapshot.val();
    if(POInfo){
      let POKey=Object.keys(POInfo)[0];
      let PO=POInfo[POKey]
      
      let POs = [];
      let content=[];
      
      let title=[
          "快遞單號",                            //A
          "品項名",                              //B
          "數量",                                //C
          "報關金額",
         
        ];

       POs.push(title);

       
      
       Object.keys(PO).forEach(function(key) {
             var po = PO[key];
             Object.keys(po).forEach(function(itemname) {
                  let poArray = [
                    key,                                      //A
                    itemname,                                 //B
                    po[itemname].Number,                      //C
                    po[itemname].Amount,                      //D      
                  ]
                  POs.push(poArray);

                  if(content[itemname]){
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }else{
                    content[itemname]={}
                    content[itemname]['Number']=[]
                    content[itemname]['Amount']=[]
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }
                  
             })

     });

     function SumDatareduce(arr){
      return arr.reduce((a,b)=>a+b);  
     }

     Object.keys(content).forEach(function(key) {
            let Array = [
              '總數',
              key,                                      
              SumDatareduce(content[key].Number),
              SumDatareduce(content[key].Amount),                            
            ]
            POs.push(Array);
     })


      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day+'-'+this.state.customerNumber}-HK內容物清單.xlsx`);
      
    
    }
    })
  }

  itemCalculationJPFile(){
    ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/JP').on('value', (snapshot)=> {
    
    let POInfo=snapshot.val();
    if(POInfo){
      let POKey=Object.keys(POInfo)[0];
      let PO=POInfo[POKey]
      
      let POs = [];
      let content=[];
      
      let title=[
          "快遞單號",                            //A
          "品項名",                              //B
          "數量",                                //C
          "報關金額",
         
        ];

       POs.push(title);

       
      
       Object.keys(PO).forEach(function(key) {
             var po = PO[key];
             Object.keys(po).forEach(function(itemname) {
                  let poArray = [
                    key,                                      //A
                    itemname,                                 //B
                    po[itemname].Number,                      //C
                    po[itemname].Amount,                      //D      
                  ]
                  POs.push(poArray);

                  if(content[itemname]){
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }else{
                    content[itemname]={}
                    content[itemname]['Number']=[]
                    content[itemname]['Amount']=[]
                    content[itemname].Number.push(Number(po[itemname].Number));
                    content[itemname].Amount.push(Number(po[itemname].Amount));
                  }
                  
             })

     });

     function SumDatareduce(arr){
      return arr.reduce((a,b)=>a+b);  
     }

     Object.keys(content).forEach(function(key) {
            let Array = [
              '總數',
              key,                                      
              SumDatareduce(content[key].Number),
              SumDatareduce(content[key].Amount),                            
            ]
            POs.push(Array);
     })


      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day+'-'+this.state.customerNumber}-JP內容物清單.xlsx`);
      
    
    }
    })
  }

  itemCalculationAUFile() {
    ref.child('/infos/customers/' + this.state.customerNumber + '/' + this.state.year + '/' + this.state.month + '/' + this.state.day + '/contentCalculateBase/AU').on('value', (snapshot) => {

      let POInfo = snapshot.val();
      if (POInfo) {
        let POKey = Object.keys(POInfo)[0];
        let PO = POInfo[POKey]

        let POs = [];
        let content = [];

        let title = [
          "快遞單號",                            //A
          "品項名",                              //B
          "數量",                                //C
          "報關金額",

        ];

        POs.push(title);



        Object.keys(PO).forEach(function (key) {
          var po = PO[key];
          Object.keys(po).forEach(function (itemname) {
            let poArray = [
              key,                                      //A
              itemname,                                 //B
              po[itemname].Number,                      //C
              po[itemname].Amount,                      //D      
            ]
            POs.push(poArray);

            if (content[itemname]) {
              content[itemname].Number.push(Number(po[itemname].Number));
              content[itemname].Amount.push(Number(po[itemname].Amount));
            } else {
              content[itemname] = {}
              content[itemname]['Number'] = []
              content[itemname]['Amount'] = []
              content[itemname].Number.push(Number(po[itemname].Number));
              content[itemname].Amount.push(Number(po[itemname].Amount));
            }

          })

        });

        function SumDatareduce(arr) {
          return arr.reduce((a, b) => a + b);
        }

        Object.keys(content).forEach(function (key) {
          let Array = [
            '總數',
            key,
            SumDatareduce(content[key].Number),
            SumDatareduce(content[key].Amount),
          ]
          POs.push(Array);
        })


        //創造一張新的表
        const wb = XLSX.utils.book_new();
        //下面這句就是把POs放進我們的sheet裡面
        const wsAll = XLSX.utils.aoa_to_sheet(POs);
        //把Sheet放進我們的Excel檔案裡面
        XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

        //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        //利用saveAs就可以把這個檔案存出來
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day + '-' + this.state.customerNumber}-AU內容物清單.xlsx`);


      }
    })
  }

  itemCalculationKRFile() {
    ref.child('/infos/customers/' + this.state.customerNumber + '/' + this.state.year + '/' + this.state.month + '/' + this.state.day + '/contentCalculateBase/KR').on('value', (snapshot) => {

      let POInfo = snapshot.val();
      if (POInfo) {
        let POKey = Object.keys(POInfo)[0];
        let PO = POInfo[POKey]

        let POs = [];
        let content = [];

        let title = [
          "快遞單號",                            //A
          "品項名",                              //B
          "數量",                                //C
          "報關金額",

        ];

        POs.push(title);



        Object.keys(PO).forEach(function (key) {
          var po = PO[key];
          Object.keys(po).forEach(function (itemname) {
            let poArray = [
              key,                                      //A
              itemname,                                 //B
              po[itemname].Number,                      //C
              po[itemname].Amount,                      //D      
            ]
            POs.push(poArray);

            if (content[itemname]) {
              content[itemname].Number.push(Number(po[itemname].Number));
              content[itemname].Amount.push(Number(po[itemname].Amount));
            } else {
              content[itemname] = {}
              content[itemname]['Number'] = []
              content[itemname]['Amount'] = []
              content[itemname].Number.push(Number(po[itemname].Number));
              content[itemname].Amount.push(Number(po[itemname].Amount));
            }

          })

        });

        function SumDatareduce(arr) {
          return arr.reduce((a, b) => a + b);
        }

        Object.keys(content).forEach(function (key) {
          let Array = [
            '總數',
            key,
            SumDatareduce(content[key].Number),
            SumDatareduce(content[key].Amount),
          ]
          POs.push(Array);
        })


        //創造一張新的表
        const wb = XLSX.utils.book_new();
        //下面這句就是把POs放進我們的sheet裡面
        const wsAll = XLSX.utils.aoa_to_sheet(POs);
        //把Sheet放進我們的Excel檔案裡面
        XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

        //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        //利用saveAs就可以把這個檔案存出來
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day + '-' + this.state.customerNumber}-KR內容物清單.xlsx`);


      }
    })
  }

  itemCalculationTHFile() {
    ref.child('/infos/customers/' + this.state.customerNumber + '/' + this.state.year + '/' + this.state.month + '/' + this.state.day + '/contentCalculateBase/TH').on('value', (snapshot) => {

      let POInfo = snapshot.val();
      if (POInfo) {
        let POKey = Object.keys(POInfo)[0];
        let PO = POInfo[POKey]

        let POs = [];
        let content = [];

        let title = [
          "快遞單號",                            //A
          "品項名",                              //B
          "數量",                                //C
          "報關金額",

        ];

        POs.push(title);



        Object.keys(PO).forEach(function (key) {
          var po = PO[key];
          Object.keys(po).forEach(function (itemname) {
            let poArray = [
              key,                                      //A
              itemname,                                 //B
              po[itemname].Number,                      //C
              po[itemname].Amount,                      //D      
            ]
            POs.push(poArray);

            if (content[itemname]) {
              content[itemname].Number.push(Number(po[itemname].Number));
              content[itemname].Amount.push(Number(po[itemname].Amount));
            } else {
              content[itemname] = {}
              content[itemname]['Number'] = []
              content[itemname]['Amount'] = []
              content[itemname].Number.push(Number(po[itemname].Number));
              content[itemname].Amount.push(Number(po[itemname].Amount));
            }

          })

        });

        function SumDatareduce(arr) {
          return arr.reduce((a, b) => a + b);
        }

        Object.keys(content).forEach(function (key) {
          let Array = [
            '總數',
            key,
            SumDatareduce(content[key].Number),
            SumDatareduce(content[key].Amount),
          ]
          POs.push(Array);
        })


        //創造一張新的表
        const wb = XLSX.utils.book_new();
        //下面這句就是把POs放進我們的sheet裡面
        const wsAll = XLSX.utils.aoa_to_sheet(POs);
        //把Sheet放進我們的Excel檔案裡面
        XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

        //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        //利用saveAs就可以把這個檔案存出來
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day + '-' + this.state.customerNumber}-TH內容物清單.xlsx`);


      }
    })
  }
  
  itemCalculationUSFile() {
    ref.child('/infos/customers/' + this.state.customerNumber + '/' + this.state.year + '/' + this.state.month + '/' + this.state.day + '/contentCalculateBase/US').on('value', (snapshot) => {

      let POInfo = snapshot.val();
      if (POInfo) {
        let POKey = Object.keys(POInfo)[0];
        let PO = POInfo[POKey]

        let POs = [];
        let content = [];

        let title = [
          "快遞單號",                            //A
          "品項名",                              //B
          "數量",                                //C
          "報關金額",

        ];

        POs.push(title);



        Object.keys(PO).forEach(function (key) {
          var po = PO[key];
          Object.keys(po).forEach(function (itemname) {
            let poArray = [
              key,                                      //A
              itemname,                                 //B
              po[itemname].Number,                      //C
              po[itemname].Amount,                      //D      
            ]
            POs.push(poArray);

            if (content[itemname]) {
              content[itemname].Number.push(Number(po[itemname].Number));
              content[itemname].Amount.push(Number(po[itemname].Amount));
            } else {
              content[itemname] = {}
              content[itemname]['Number'] = []
              content[itemname]['Amount'] = []
              content[itemname].Number.push(Number(po[itemname].Number));
              content[itemname].Amount.push(Number(po[itemname].Amount));
            }

          })

        });

        function SumDatareduce(arr) {
          return arr.reduce((a, b) => a + b);
        }

        Object.keys(content).forEach(function (key) {
          let Array = [
            '總數',
            key,
            SumDatareduce(content[key].Number),
            SumDatareduce(content[key].Amount),
          ]
          POs.push(Array);
        })


        //創造一張新的表
        const wb = XLSX.utils.book_new();
        //下面這句就是把POs放進我們的sheet裡面
        const wsAll = XLSX.utils.aoa_to_sheet(POs);
        //把Sheet放進我們的Excel檔案裡面
        XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

        //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        //利用saveAs就可以把這個檔案存出來
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day + '-' + this.state.customerNumber}-US內容物清單.xlsx`);


      }
    })
  }

  ExportJPInvoice(){ 
      //先暫時把資料塞在這裡，等人多了放進資料庫
      const customers={
        '80071':{
              'ID':'80071C',
              'name':'BOOKS.COM CO.,LTD.',
              'address':'10F., NO.20, ALY. 1, LN. 768, SEC. 4,BADE RD., NANGANG DIST., TAIPEI CITY 11577, TAIWAN',
              'phone':'02-27821100'
        },
        '20038':{
              'ID':'20038',
              'name':'薇恩有限公司 ROBIN MAY',
              'address':'No. 52, Section 2, Wuchang Street, Taipei City Taiwan',
              'phone':'02-23881311'
        }

      }

      const JPInvoice=(POInfo)=>{
          let customerID=customers[this.state.customerID]['ID']
          let shipper=customers[this.state.customerID]['name']
          let address=customers[this.state.customerID]['address']
          let phone=customers[this.state.customerID]['phone']

          const POkeys = Object.keys(POInfo);
          var last=POkeys.pop();

          pdfmake.vfs = pdffonts.pdfMake.vfs;
          
          //你只要印很多張，你就需要這個LabelArr，把他們排列一下
          var LabelArr = []       
          
          Object.keys(POInfo).forEach((key)=> {
              var po=POInfo[key];
              var itemNumber=po.itemNumber
              
              var poContent=[
                  [
                    {text:'No of PKG\n包裹件數',
                    alignment: 'center',
                    },
                    {text:'Description\n物品內容',
                    alignment: 'center',
                    },
                    {text:'Quantity\n物品數量',
                    alignment: 'center',
                    },
                    {text:'Unit Price\n單價',
                    alignment: 'center',
                    },
                    {text:'Amount\n總價/TWD',
                    alignment: 'center',
                    }
                  ],
              ]
              for(var i=1;i<Number(itemNumber)+1;i++){
                
                var item=[]
                var 包裹件數= {text:'',
                          alignment: 'center',}
                包裹件數.text=1
                item.push(包裹件數)
                var 物品內容= {text:'',
                          alignment: 'center',}
                物品內容.text=po['itemName'+i]
                item.push(物品內容)
                var 物品數量= {text:'',
                          alignment: 'center',}
                物品數量.text=po['itemNumber'+i]
                item.push(物品數量)
                var 單價= {text:'',
                          alignment: 'center',}
                單價.text=(Number(po['itemAmount'+i])/Number(po['itemNumber'+i])).toFixed(1)
                item.push(單價)
                var 總價= {text:'',
                          alignment: 'center',}
                總價.text=po['itemAmount'+i]
                item.push(總價)
                
                poContent.push(item)
              }
              //這是要加一欄，個人使用
              poContent.push(
                [
                  {text:'Personal Use',
                  alignment: 'center',
                  colSpan: 5
                  },
                  '',
                  '',
                  '',
                  ''
                ],
              )

              //總金額的部分要加進來
              poContent.push(
                [
                  {text:'TOTAL/總金額:',
                  alignment: 'right',
                  colSpan: 4
                  },
                  '',
                  '',
                  '',
                  {text:po.報關總值,
                  alignment: 'center',
                  }
                ],
              )

              //PDFmake就是把一頁當成一個[]，把每一個區塊當成一個{}
              //PDFmake靠colSpan或是rowSpan做欄位合併，有span的{}出現的時候，相對應附近的欄位就要留空，給他span
              if(key==last){ 
                let Label= [ 
                  {
                  text: 'COMMERCIAL INVOICE\n\n',
                  fontSize: 36,
                  alignment: 'center'
                  },  
                  { table: {
                    widths: [120,80, 120, '*'],
                    body: [
                      
                      [
                        {text:'Date/日期: ',
                        margin: [0, 8, 0, 0],
                        rowSpan: 2},
                        {text:po.timeTW.slice(0, 9),
                        alignment: 'center',
                        margin: [0, 8, 0, 0],
                        rowSpan: 2},
                        {text:'PO NO./訂單號碼:',
                        },
                        {text:po.訂單單號,
                        alignment: 'center',
                        },
                        
                      ],
                      [
                        '',
                        '',
                        {text:'CWB NO./提單號碼:',
                        },
                        {text:po.快遞單號,
                        alignment: 'center',
                        },
                        
                      ],
                      [
                        {text:'Consignee/收件人:',
                        },
                        {text:po.收件人姓名,
                        alignment: 'left',
                        colSpan: 3
                        },
                        '',
                        '',              
                      ],
                      [
                        {text:'Address/地址:',
                        },
                        {text:po.收件地址,
                        alignment: 'left',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
                      [
                        {text:'TEL/電話:',
                        },
                        {text:po.收件連絡電話,
                        alignment: 'left',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
                    ]
                  },
                  },
                  { table: {
                    widths: ['*', '*', '*', '*','*'],
                    //下面body的內容因為牽扯到物件，所以在外面算，然後帶進來
                    body:poContent
                    }//-->table
                  },
                  { table: {
                    widths: [120, '*', '*', '*'],
                    body: [
                      [
                        {text:'Shipper/發貨人:',
                        },
                        {text:shipper,
                        alignment: 'center',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
                      [
                        {text:'Address/地址:',
                        },
                        {text:address,
                        alignment: 'center',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
                      [
                        {text:'TEL/電話:',
                        },
                        {text:phone,
                        alignment: 'center',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
      
                    ]//-->body
                    },//-->table
                    //pageBreak要放在最後一個{}裡面，這樣才可以作用
                    //最後一頁不用換頁
                    //pageBreak: 'after'
                  }
                  
      
                ]
                LabelArr.push(Label);
              }else{
                let Label= [ 
                  {
                  text: 'COMMERCIAL INVOICE\n\n',
                  fontSize: 36,
                  alignment: 'center'
                  },  
                  { table: {
                    widths: [120,80, 120, '*'],
                    body: [
                      
                      [
                        {text:'Date/日期: ',
                        margin: [0, 8, 0, 0],
                        rowSpan: 2},
                        {text:po.timeTW.slice(0, 9),
                        alignment: 'center',
                        margin: [0, 8, 0, 0],
                        rowSpan: 2},
                        {text:'PO NO./訂單號碼:',
                        },
                        {text:po.訂單單號,
                        alignment: 'center',
                        },
                        
                      ],
                      [
                        '',
                        '',
                        {text:'CWB NO./提單號碼:',
                        },
                        {text:po.快遞單號,
                        alignment: 'center',
                        },
                        
                      ],
                      [
                        {text:'Consignee/收件人:',
                        },
                        {text:po.收件人姓名,
                        alignment: 'left',
                        colSpan: 3
                        },
                        '',
                        '',              
                      ],
                      [
                        {text:'Address/地址:',
                        },
                        {text:po.收件地址,
                        alignment: 'left',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
                      [
                        {text:'TEL/電話:',
                        },
                        {text:po.收件連絡電話,
                        alignment: 'left',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
                    ]
                  },
                  },
                  { table: {
                    widths: ['*', '*', '*', '*','*'],
                    //下面body的內容因為牽扯到物件，所以在外面算，然後帶進來
                    body:poContent
                    }//-->table
                  },
                  { table: {
                    widths: [120, '*', '*', '*'],
                    body: [
                      [
                        {text:'Shipper/發貨人:',
                        },
                        {text:shipper,
                        alignment: 'center',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
                      [
                        {text:'Address/地址:',
                        },
                        {text:address,
                        alignment: 'center',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
                      [
                        {text:'TEL/電話:',
                        },
                        {text:phone,
                        alignment: 'center',
                        colSpan: 3
                        },
                        '',
                        '',
                      ],
      
                    ]//-->body
                    },//-->table
                    //pageBreak要放在最後一個{}裡面，這樣才可以作用
                    pageBreak: 'after'
                  }
                  
      
                ]
                LabelArr.push(Label);
              }
              
          });

          
          pdfmake.fonts = {
          ch: {
          normal: 'kaiu.ttf',
          bold: 'kaiu.ttf',
          italics: 'kaiu.ttf',
          bolditalics: 'kaiu.ttf'
          }};

          var docDefinition = {
              content:LabelArr,
              defaultStyle: {
              font: 'ch',
              fontSize: 14
              } ,
              styles: {
                head: {  
                  fontSize: 66,
                  bold: true
                },

              },

              // a string or { width: number, height: number }
              // 下面是10*10的標籤設計
              pageSize: { width: 595, height: 842},
              

              // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
              pageMargins: [ 50, 50, 50, 0 ],
            };
            
            let labeltitle=this.state.year+this.state.month+this.state.day+'-'+this.state.customerID+"-JP發票.pdf";
            pdfmake.createPdf(docDefinition).download(labeltitle);
            
      }
      
      ref.child('/infos/customers/'+this.state.customerID+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/JP').once('value').then((snapshot)=> {
        let POInfo=snapshot.val();
        let POKey=Object.keys(POInfo)[0];
        let PO=POInfo[POKey]
        let target=[]
        let tempo={}

        //把快遞單號取出來，並建立一個新的用單號為key的obj
        Object.keys(PO).forEach(function(key) {
            var deliveryNumber=PO[key].快遞單號
            target.push(deliveryNumber)
            tempo[deliveryNumber]=PO[key]
        })
        //把單號排列一下
        target.sort()
        
        POInfo={}

        target.forEach(function(key){
          POInfo[key]=tempo[key]
        });
    
        //console.log(POInfo)
        JPInvoice(POInfo)
      })//-->ref的收尾
      
  }

  ExportJPLabelA4(){
      //先暫時把資料塞在這裡，等人多了放進資料庫
      const customers={
          '80071':{
                'ID':'80071C',
                'name':'BOOKS.COM CO.,LTD.',
                'address':'10F., NO.20, ALY. 1, LN. 768, SEC. 4,BADE RD., NANGANG DIST., TAIPEI CITY 11577, TAIWAN',
                'phone':'02-27821100'
          },
          '20038':{
                'ID':'20038',
                'name':'薇恩有限公司 ROBIN MAY',
                'address':'No. 52, Section 2, Wuchang Street, Taipei City Taiwan',
                'phone':'02-23881311'
          }

      }

      // console.log(customers['80071'])
      
      //這是實際上印製的檔案
      const JPLabelA4=(goodData)=>{

          let customerID=customers[this.state.customerID]['ID']
          let shipper=customers[this.state.customerID]['name']
          let address=customers[this.state.customerID]['address']
          let phone=customers[this.state.customerID]['phone']

          pdfmake.vfs = pdffonts.pdfMake.vfs;
          //把印製的Label格式準備好
          var LabelArr = [];

          //把最後一張訂單挑出來，因為這張訂單要特別處理，不要接下一頁
          const keys = Object.keys(goodData);
          
          var last=keys.pop();
         
          //開始處理每張訂單，導進標籤印製群組
          Object.keys(goodData).forEach((key)=> {
            var po=goodData[key];
            
            //logo是跨境通的logo，在這裡先導進來
            var logo=LogoImg();
            var dataURL=textToBase64Barcode(po.快遞單號);
            //下面這句定義要印幾張
            var LabelNumber=1;
              
              //馬來西亞的標籤數需求就是總箱數再加1張，就這麼簡單
                for(var i=0;i<LabelNumber;i++){
                  //如果是最後一張單的最後一張標，就不要再下一頁了，這是唯一需要特別處理的。
                  if(key===last && i==LabelNumber-1){         
                    let Label=[
                      { table: {
                        widths: ['auto', '*', '*', '*', '*', '*'],
                        //下面是要做一開始的logo、barcode、和國碼標
                        body: [
                          [
                            {
                            // you'll most often use dataURI images on the browser side
                            // if no width/height/fit is provided, the original size will be used
                            image:logo,
                            width:100, 
                            height:20,
                            alignment: 'center',
                            colSpan: 3},
                            '',
                            '',
                            { rowSpan: 3,
                              colSpan: 3,
                              text: po.國別,
                              style: 'head',  
                              width:100, 
                              alignment: 'center',          
                            },
                            '',
                            ''
      
                          ],
                          [{ image:dataURL,
                            colSpan: 3,
                            width:140, 
                            height:50,
                            alignment: 'center',
                          },
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{ text:po.快遞單號,
                            colSpan: 3,
                            width:140, 
                            height:10,
                            alignment: 'center',
                          },
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
                          //這是馬來西亞標籤特別要加的
                          [{ text: 'A/C NO:',fontSize: 14},
                          {text:customerID,
                            colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{ text: 'PO:',fontSize: 14},
                          {text:po.訂單單號,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Shipper:',fontSize: 14},
                          {text: shipper,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Shipper TEL:',fontSize: 14},
                          {text: phone,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Shipper Address:',fontSize: 14},
                          {text: address,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Consignee:',fontSize: 14},
                          {text: po.收件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Consignee TEL:',fontSize: 14},
                          {text: po.收件連絡電話,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Consignee Address:',rowSpan: 2,fontSize: 14},
                          {text: po.收件地址,rowSpan: 2,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{},
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Package:',fontSize: 14},
                          {text: po['總箱數'],colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [
                          //   {text: 'Declared Value:',fontSize: 14},
                          // {text: po['報關總值'],colSpan: 5},
                          {},
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Weight:',fontSize: 14},
                          //KGS前面後一格，看起來比較好看
                          {text: po['總重量-公斤']+' KGS',colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{text: 'Description:',fontSize: 14},
                          {text: po.內容物,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{text: '代收款:',fontSize: 14},
                          {text: po.payment,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                        ]
                      }},     
                    ];
                    LabelArr.push(Label);
                    
                  }else{
                    
                  //一般情況下都是跑下面這張標籤        
                    let Label=[
                      { table: {
                        widths: ['auto', '*', '*', '*', '*', '*'],
                        //下面是要做一開始的logo、barcode、和國碼標
                        body: [
                          [
                            {
                            // you'll most often use dataURI images on the browser side
                            // if no width/height/fit is provided, the original size will be used
                            image:logo,
                            width:100, 
                            height:20,
                            alignment: 'center',
                            colSpan: 3},
                            '',
                            '',
                            { rowSpan: 3,
                              colSpan: 3,
                              text: po.國別,
                              style: 'head',  
                              width:100, 
                              alignment: 'center',          
                            },
                            '',
                            ''
      
                          ],
                          [{ image:dataURL,
                            colSpan: 3,
                            width:140, 
                            height:50,
                            alignment: 'center',
                          },
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{ text:po.快遞單號,
                            colSpan: 3,
                            width:140, 
                            height:10,
                            alignment: 'center',
                          },
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
                          //這是馬來西亞標籤特別要加的
                          [{ text: 'A/C NO:',fontSize: 14},
                          {text:'80071C',
                            colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{ text: 'PO:',fontSize: 14},
                          {text:po.訂單單號,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Shipper:',fontSize: 14},
                          {text: shipper,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Shipper TEL:',fontSize: 14},
                          {text: phone,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Shipper Address:',fontSize: 14},
                          {text: address,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Consignee:',fontSize: 14},
                          {text: po.收件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Consignee TEL:',fontSize: 14},
                          {text: po.收件連絡電話,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Consignee Address:',rowSpan: 2,fontSize: 14},
                          {text: po.收件地址,rowSpan: 2,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{},
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Package:',fontSize: 14},
                          {text: po['總箱數'],colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [
                          //   {text: 'Declared Value:',fontSize: 14},
                          // {text: po['報關總值'],colSpan: 5},
                          {},
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                          [{text: 'Weight:',fontSize: 14},
                          //KGS前面後一格，看起來比較好看
                          {text: po['總重量-公斤']+' KGS',colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{text: 'Description:',fontSize: 14},
                          {text: po.內容物,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{text: '代收款:',fontSize: 14},
                          {text: po.payment,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
      
                        ]
                        },
                        pageBreak: 'after'
                      }, 
                    
                    ];

                    LabelArr.push(Label);
                    
                  };
                };

          });

          
          pdfmake.fonts = {
          ch: {
          normal: 'kaiu.ttf',
          bold: 'kaiu.ttf',
          italics: 'kaiu.ttf',
          bolditalics: 'kaiu.ttf'
          }};

          var docDefinition = {
              content:LabelArr,
              defaultStyle: {
              font: 'ch',
              fontSize: 18
              } ,
              styles: {
                head: {          
                  fontSize: 66,
                  bold: true
                },
              },

              // a string or { width: number, height: number }
              // 下面是10*10的標籤設計
              pageSize: { width: 595, height: 842},
              

              // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
              pageMargins: [ 20, 10,20, 0 ],
          };
          
          let labeltitle=this.state.year+this.state.month+this.state.day+'-'+this.state.customerID+'-JP提單.pdf';
          
          pdfmake.createPdf(docDefinition).download(labeltitle);
          //pdfmake.createPdf(docDefinition).open();  
          
      }

      ref.child('/infos/customers/'+this.state.customerID+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/JP').once('value').then((snapshot)=> {
        let POInfo=snapshot.val();
        let POKey=Object.keys(POInfo)[0];
        let PO=POInfo[POKey]
        let target=[]
        let tempo={}

        //把快遞單號取出來，並建立一個新的用單號為key的obj
        Object.keys(PO).forEach(function(key) {
            var deliveryNumber=PO[key].快遞單號
            target.push(deliveryNumber)
            tempo[deliveryNumber]=PO[key]
        })
        //把單號排列一下
        target.sort()
        
        POInfo={}

        target.forEach(function(key){
          POInfo[key]=tempo[key]
        });
    
        //console.log(POInfo)
        JPLabelA4(POInfo)
      })//-->ref的收尾  
  }

  //這是所有欄位普用型的function
  handleInputChange(e){
    this.setState({ [e.target.name]: e.target.value });  
    //console.log(this.state)
  }

  addToDatabase(){
    var start=this.state.deliveryNumberStart;
    var stop=this.state.deliveryNumberStop;
    var data={}
    ref.child('/infos/customers/JPDeliveryNumberData').once('value').then((snapshot)=> {

          if(snapshot.val()==null){
            
          }else{
             data=snapshot.val()
          }


          for(var i=Number(start);i<Number(stop)+1;i++){
            data[i]=0
          } 
          
          ref.child('/infos/customers/JPDeliveryNumberData').set(data)
          this.setState({ 
            deliveryNumberStart:'',
            deliveryNumberStop:'', 
          });
    }) 
  }
  
  handleTimeChange(e){
    var date=String(e.target.value) 
    var year=date.slice(0, 4)
    var month=date.slice(4, 6);
    var day=date.slice(6, 8);
    this.setState({ 
      'year':year,
      'month':month,
      'day':day
    });  
    //console.log(this.state)
  }

  changeDeliveryNation(e,{ value }){
    this.setState({
      'deliveryNation':value,
    }) 
  
  }

  ExportDeleteRecord(){
    ref.child('/infos/customers/80071'+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/deleteRecord').once('value').then((snapshot)=> {
      let POInfo=snapshot.val();
      if(POInfo){
       
        let PO=POInfo
        
        let POs = [];
        let content=[];
        
        let title=[
            "國家",
            "快遞單號",                            //A
            "訂單單號",                              //B
            "件數",                                //C
            "收件人姓名",
            "收件地址",
            "收件連絡電話",
            "內容物",
            "重量",
            "總值",

          ];

        POs.push(title);

        
        
        Object.keys(PO).forEach(function(key) {
              var po = PO[key]['order'];
              let poArray = [
                po.國別,
                po.快遞單號,
                po.訂單單號,
                po.總箱數,
                po.收件人姓名,
                po.收件地址,
                po.收件連絡電話,
                po.內容物,
                po['總重量-公斤'],
                po.報關總值   
              ]
              
              POs.push(poArray);
        });

    


        //創造一張新的表
        const wb = XLSX.utils.book_new();
        //下面這句就是把POs放進我們的sheet裡面
        const wsAll = XLSX.utils.aoa_to_sheet(POs);
        //把Sheet放進我們的Excel檔案裡面
        XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

        //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
        //利用saveAs就可以把這個檔案存出來
        saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-80071-快遞單刪除清單.xlsx`);
        
      
      }




    })//-->ref的收尾

  }


  ExportSGFile(PO){
    let POs = [];
      let title=[
          "跨境通提單號碼",              //A
          "訂單號碼",          //B
          "件數",                  //C
          "收件人",              //D
          "地址",           //E
          "電話",        //F
          "內容",               //G
          "重量",    //H
          "價值 NT$",            //I
          "郵遞區號",           //J
          "新幣價值",
        ];

       POs.push(title);

       
       var PoInfo=PO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    po.快遞單號,                 
                    po.訂單單號,                 
                    po.總箱數, 
                    po.收件人姓名,
                    po.收件地址,
                    po.收件連絡電話,
                    po.內容物,
                    Number(po['總重量-公斤']),                  
                    po['報關總值'],     
                    po.收件人郵遞區號,  
                    Number((po['報關總值']/22).toFixed(1)),                  
                   
                  ];
                  POs.push(poArray);
              }else{
                  
              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-SG2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }

  //下面這個是要把資料導出，丟公司系統
  ExportSGSystemFile(PO){
     //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
     let POs = [[
         "HAWB",                      //A
         "單號",                      //B
         "寄件人",                    //C
         "寄件地址",                  //D
         "收件人",                    //E
         "收件地址",                  //F
         "PKG",                      //G
         "內容物",                    //H
         "件數",                      //I
         "公斤數",                    //J
         "報關價值",                  //K
         "Remark",                   //L
         "consignee City",           //M
         "Consignee Region",         //N
         "Consignee ZIP Code",       //O
         "Contact Person",           //P
         "連絡電話",                  //Q
         "Personal ID / KTP",        //R
         "Reference Number",         //S
         "DOX/SPX",                  //T
         "CC/PP",                    //U
         "CC Amount",                //V
         "Currency"                  //W
        ]];
      var PoInfo=PO;
      

      Object.keys(PoInfo).forEach(function(key) {
        var po = PoInfo[key];

        if (typeof po.內容物 =='string'){
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po.內容物,                      //內容物
            po.總箱數,                      //件數
            Number(po['總重量-公斤']),               //公斤數
            po['報關總值'],                 //報關價值
            "",                             //Remark
            "",                             //consignee City
            "SIN",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.收件人姓名,                   //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "SPX",                         //DOX/SPX
            "PP",                          //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];
          //console.log(poArray);
          POs.push(poArray);

        }else{

              var size = Object.keys(po.內容物).length;
              if(size===1){
                  let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,                      //內容物
                    po['內容物'][0].件數,                             //件數
                    po['總重量-公斤'],                      //公斤數
                    po['報關總值'],                  //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "SIN",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
                  ];
                  //console.log(poArray);
                  POs.push(poArray);
            }else{
              let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,          //內容物
                    po['內容物'][0].件數,            //件數
                    po['總重量-公斤'],               //公斤數
                    po['報關總值'],                  //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "SIN",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
              ];

              POs.push(poArray);
              var i;
              for(i=1;i<size;i++){

                let poArray = [
                  "",
                  po.快遞單號,
                  "",
                  "",
                  "",
                  "",
                  "",
                  po['內容物'][i].內容物,
                  po['內容物'][i].件數,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                ];
                POs.push(poArray);
              }
            }

        }

        
        

      });

     //創造一張新的表
     const wb = XLSX.utils.book_new();
     //下面這句就是把POs放進我們的sheet裡面
     const wsAll = XLSX.utils.aoa_to_sheet(POs);
     //把Sheet放進我們的Excel檔案裡面
     XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

     //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
     var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
     //利用saveAs就可以把這個檔案存出來
     saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-SG上傳公司系統訂單.xlsx`);
     //把表命名做出來
     //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }




    
  ExportMYFile(PO){
    let POs = [];
      let title=[
          "序NO",                                      //A
          "快递单号Tracking No",                        //B
          "唛头Marking",                                //C
          "袋子号码Bag No",                             //D
          "收货人姓名Reciever",                         //E
          "联络电话Tel",                                //F
          "收货地址Address",                            //G
          "邮区编号Postcode",                           //H
          "箱数Carton",                                 //I
          "总重量Total Weight (Kg)E",                   //J
          "计费重量Chargeable Weight (KG)",             //K
          "首一公斤运费1st 1 Kg",                       //L
          "续一公斤运费Following 1 Kg",                 //M
          "计费重量",                                   //N
          "小计Sub Total (RM)",                         //O
         
        ];

       POs.push(title);

       
       var PoInfo=PO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    "",                                      //A
                    po.快遞單號,                              //B
                    "",                                      //C
                    "",                                      //D
                    po.收件人姓名,                            //E
                    po.收件連絡電話,                          //F
                    po.收件地址,                              //G
                    po.收件人郵遞區號,                                      //H
                    po.總箱數,                                //I
                    Number(po['總重量-公斤']),                                //J
                    "",                                      //K
                    "",                                      //L
                    "",                                      //M
                    "",                                      //N
                    "",                                      //O
                  ];
                  POs.push(poArray);
              }else{
                  /*               
                  let content=po.內容物;
                  let temp=[];
                  let PO='';
                  Object.keys(content).forEach(function(key) { 
                    let ItemName=content[key]['內容物'];
                    let ItemPCs=content[key]['件數'];              
                    temp.push(`${ItemName}x${ItemPCs}`)
                  });
                  Object.keys(temp).forEach(function(key) { 
                    PO=PO+`${temp[key]} `;
                  });
                  */

                  let poArray = [
                    "",                                      //A
                    po.快遞單號,                              //B
                    "",                                      //C
                    "",                                      //D
                    po.收件人姓名,                            //E
                    po.收件連絡電話,                          //F
                    po.收件地址,                              //G
                    po.收件人郵遞區號,                                      //H
                    po.總箱數,                                //I
                    po['總重量-公斤'],                                //J
                    "",                                      //K
                    "",                                      //L
                    "",                                      //M
                    "",                                      //N
                    "",                                      //O
                  ];
                  POs.push(poArray);
                  


              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-MY2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }

  //下面這個是要把資料導出，丟公司系統
  ExportMYSystemFile(PO){
     //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
     let POs = [[
         "HAWB",                      //A
         "單號",                      //B
         "寄件人",                    //C
         "寄件地址",                  //D
         "收件人",                    //E
         "收件地址",                  //F
         "PKG",                      //G
         "內容物",                    //H
         "件數",                      //I
         "公斤數",                    //J
         "報關價值",                  //K
         "Remark",                   //L
         "consignee City",           //M
         "Consignee Region",         //N
         "Consignee ZIP Code",       //O
         "Contact Person",           //P
         "連絡電話",                  //Q
         "Personal ID / KTP",        //R
         "Reference Number",         //S
         "DOX/SPX",                  //T
         "CC/PP",                    //U
         "CC Amount",                //V
         "Currency"                  //W
        ]];
      
      
      var PoInfo=PO;

   
      Object.keys(PoInfo).forEach(function(key) {
        var po = PoInfo[key];

        if (typeof po.內容物 =='string'){
          if(po['箱長']===undefined){
            let poArray = [
              '',                             //HAWB
              po.快遞單號,                     //單號
              po.寄件人姓名,                   //寄件人
              po.寄件地址,                     //寄件地址
              po.收件人姓名,                   //收件人
              po.收件地址,                     //收件地址
              "",                             //PKG
              po.內容物,                      //內容物
              po.總箱數,                      //件數
              Number(po['總重量-公斤']),               //公斤數
              po['報關總值'],         //報關價值
              "",                             //Remark
              "",                             //consignee City
              "KUL",                        //Consignee Region
              po.收件人郵遞區號,               //Consignee ZIP Code
              po.收件人姓名,                      //Contact Person
              po.收件連絡電話,                //連絡電話
              "",                            //Personal ID / KTP
              "",                            //Reference Number
              "SPX",                            //DOX/SPX
              "PP",                            //CC/PP
              "",                            //CC Amount
              "",                         //Currency
            ];
            //console.log(poArray);
            POs.push(poArray);

          }else{
            let poArray = [
              '',                             //HAWB
              po.快遞單號,                     //單號
              po.寄件人姓名,                   //寄件人
              po.寄件地址,                     //寄件地址
              po.收件人姓名,                   //收件人
              po.收件地址,                     //收件地址
              "",                             //PKG
              po.內容物,                      //內容物
              po.總箱數,                      //件數
              Number(po['總重量-公斤']),               //公斤數
              po['報關總值'],                          //報關價值
              po['箱長']+'*'+po['箱寬']+'*'+po['箱高'], //Remark  有要算材積重的，要給跨境通，
              "",                             //consignee City
              "KUL",                        //Consignee Region
              po.收件人郵遞區號,               //Consignee ZIP Code
              po.收件人姓名,                      //Contact Person
              po.收件連絡電話,                //連絡電話
              "",                            //Personal ID / KTP
              "",                            //Reference Number
              "SPX",                            //DOX/SPX
              "PP",                            //CC/PP
              "",                            //CC Amount
              "",                         //Currency
            ];
            //console.log(poArray);
            POs.push(poArray);

          }
         

        }else{

              var size = Object.keys(po.內容物).length;
              if(size===1){
                  let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,          //內容物
                    po['內容物'][0].件數,            //件數
                    po['總重量-公斤'],               //公斤數
                    po['報關總值'],         //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "KUL",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
                  ];
                  //console.log(poArray);
                  POs.push(poArray);
            }else{
              let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,                      //內容物
                    po['內容物'][0].件數,                             //件數
                    po['總重量-公斤'],                      //公斤數
                    po['報關總值'],         //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "KUL",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
              ];

              POs.push(poArray);
              var i;
              for(i=1;i<size;i++){

                let poArray = [
                  "",
                  po.快遞單號,
                  "",
                  "",
                  "",
                  "",
                  "",
                  po['內容物'][i].內容物,
                  po['內容物'][i].件數,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                ];
                POs.push(poArray);
              }
            }

        }

        
        

      });

     //創造一張新的表
     const wb = XLSX.utils.book_new();
     //下面這句就是把POs放進我們的sheet裡面
     const wsAll = XLSX.utils.aoa_to_sheet(POs);
     //把Sheet放進我們的Excel檔案裡面
     XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

     //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
     var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
     //利用saveAs就可以把這個檔案存出來
     saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-MY上傳公司系統訂單.xlsx`);
     //把表命名做出來
     //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }



  ExportMOFile(PO){
    let POs = [];
      let title=[
          "跨境通提單號碼",              //A
          "訂單號碼",          //B
          "件數",                  //C
          "收件人",              //D
          "地址",           //E
          "電話",        //F
          "內容",               //G
          "重量",    //H
          "價值 NT$",            //I
                             //J
          
        ];

       POs.push(title);

       
       var PoInfo=PO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    po.快遞單號,                 
                    po.訂單單號,                 
                    po.總箱數, 
                    po.收件人姓名,
                    po.收件地址,
                    po.收件連絡電話,
                    po.內容物,
                    Number(po['總重量-公斤']),                  
                    po['報關總值'],     
                                        
                   
                  ];
                  POs.push(poArray);
              }else{
                  
              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-MO2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }
  //下面這個是要把資料導出，丟公司系統
  ExportMOSystemFile(PO){
     //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
     let POs = [[
         "HAWB",                      //A
         "單號",                      //B
         "寄件人",                    //C
         "寄件地址",                  //D
         "收件人",                    //E
         "收件地址",                  //F
         "PKG",                      //G
         "內容物",                    //H
         "件數",                      //I
         "公斤數",                    //J
         "報關價值",                  //K
         "Remark",                   //L
         "consignee City",           //M
         "Consignee Region",         //N
         "Consignee ZIP Code",       //O
         "Contact Person",           //P
         "連絡電話",                  //Q
         "Personal ID / KTP",        //R
         "Reference Number",         //S
         "DOX/SPX",                  //T
         "CC/PP",                    //U
         "CC Amount",                //V
         "Currency"                  //W
        ]];
      
      
      var PoInfo=PO;

   
      Object.keys(PoInfo).forEach(function(key) {
        var po = PoInfo[key];

        if (typeof po.內容物 =='string'){
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po.內容物,                      //內容物
            po.總箱數,                      //件數
            Number(po['總重量-公斤']),               //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "MAC",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.收件人姓名,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "SPX",                            //DOX/SPX
            "PP",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];
          //console.log(poArray);
          POs.push(poArray);

        }else{

              var size = Object.keys(po.內容物).length;
              if(size===1){
                  let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,          //內容物
                    po['內容物'][0].件數,            //件數
                    po['總重量-公斤'],               //公斤數
                    po['報關總值'],         //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "MAC",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
                  ];
                  //console.log(poArray);
                  POs.push(poArray);
            }else{
              let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,                      //內容物
                    po['內容物'][0].件數,                             //件數
                    po['總重量-公斤'],                      //公斤數
                    po['報關總值'],         //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "MAC",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
              ];

              POs.push(poArray);
              var i;
              for(i=1;i<size;i++){

                let poArray = [
                  "",
                  po.快遞單號,
                  "",
                  "",
                  "",
                  "",
                  "",
                  po['內容物'][i].內容物,
                  po['內容物'][i].件數,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                ];
                POs.push(poArray);
              }
            }

        }

        
        

      });

     //創造一張新的表
     const wb = XLSX.utils.book_new();
     //下面這句就是把POs放進我們的sheet裡面
     const wsAll = XLSX.utils.aoa_to_sheet(POs);
     //把Sheet放進我們的Excel檔案裡面
     XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

     //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
     var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
     //利用saveAs就可以把這個檔案存出來
     saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-MO上傳公司系統訂單.xlsx`);
     //把表命名做出來
     //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }



  ExportHKFile(PO){
    let POs = [];
      let title=[
          "跨境通提單號碼",              //A
          "訂單號碼",          //B
          "件數",                  //C
          "收件人",              //D
          "地址",           //E
          "電話",        //F
          "內容",               //G
          "重量",    //H
          "價值 NT$",            //I
          
          
        ];

       POs.push(title);

       
       var PoInfo=PO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    po.快遞單號,                 
                    po.訂單單號,                 
                    po.總箱數, 
                    po.收件人姓名,
                    po.收件地址,
                    po.收件連絡電話,
                    po.內容物,
                    Number(po['總重量-公斤']),                  
                    po['報關總值'],     
                                        
                   
                  ];
                  POs.push(poArray);
              }else{
                  
              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-HK2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }
  //下面這個是要把資料導出，丟公司系統
  ExportHKSystemFile(PO){
    //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
    let POs = [[
        "HAWB",                      //A
        "單號",                      //B
        "寄件人",                    //C
        "寄件地址",                  //D
        "收件人",                    //E
        "收件地址",                  //F
        "PKG",                      //G
        "內容物",                    //H
        "件數",                      //I
        "公斤數",                    //J
        "報關價值",                  //K
        "Remark",                   //L
        "consignee City",           //M
        "Consignee Region",         //N
        "Consignee ZIP Code",       //O
        "Contact Person",           //P
        "連絡電話",                  //Q
        "Personal ID / KTP",        //R
        "Reference Number",         //S
        "DOX/SPX",                  //T
        "CC/PP",                    //U
        "CC Amount",                //V
        "Currency"                  //W
       ]];
     
     
     var PoInfo=PO;

  
     Object.keys(PoInfo).forEach(function(key) {
       var po = PoInfo[key];

       if (typeof po.內容物 =='string'){
         let poArray = [
           '',                             //HAWB
           po.快遞單號,                     //單號
           po.寄件人姓名,                   //寄件人
           po.寄件地址,                     //寄件地址
           po.收件人姓名,                   //收件人
           po.收件地址,                     //收件地址
           "",                             //PKG
           po.內容物,                      //內容物
           po.總箱數,                      //件數
           Number(po['總重量-公斤']),               //公斤數
           po['報關總值'],         //報關價值
           "",                             //Remark
           "",                             //consignee City
           "HKG",                        //Consignee Region
           po.收件人郵遞區號,               //Consignee ZIP Code
           po.收件人姓名,                      //Contact Person
           po.收件連絡電話,                //連絡電話
           "",                            //Personal ID / KTP
           "",                            //Reference Number
           "SPX",                            //DOX/SPX
           "PP",                            //CC/PP
           "",                            //CC Amount
           "",                         //Currency
          ];
         //console.log(poArray);
         POs.push(poArray);

       }else{

             var size = Object.keys(po.內容物).length;
             if(size===1){
                 let poArray = [
                   '',                             //HAWB
                   po.快遞單號,                     //單號
                   po.寄件人姓名,                   //寄件人
                   po.寄件地址,                     //寄件地址
                   po.收件人姓名,                   //收件人
                   po.收件地址,                     //收件地址
                   "",                             //PKG
                   po['內容物'][0].內容物,          //內容物
                   po['內容物'][0].件數,            //件數
                   po['總重量-公斤'],               //公斤數
                   po['報關總值'],         //報關價值
                   "",                             //Remark
                   "",                             //consignee City
                   "HKG",                        //Consignee Region
                   po.收件人郵遞區號,               //Consignee ZIP Code
                   po.連絡人,                      //Contact Person
                   po.收件連絡電話,                //連絡電話
                   "",                            //Personal ID / KTP
                   "",                            //Reference Number
                   "",                            //DOX/SPX
                   "",                            //CC/PP
                   "",                            //CC Amount
                   "",                         //Currency
                 ];
                 //console.log(poArray);
                 POs.push(poArray);
           }else{
             let poArray = [
                   '',                             //HAWB
                   po.快遞單號,                     //單號
                   po.寄件人姓名,                   //寄件人
                   po.寄件地址,                     //寄件地址
                   po.收件人姓名,                   //收件人
                   po.收件地址,                     //收件地址
                   "",                             //PKG
                   po['內容物'][0].內容物,                      //內容物
                   po['內容物'][0].件數,                             //件數
                   po['總重量-公斤'],                      //公斤數
                   po['報關總值'],         //報關價值
                   "",                             //Remark
                   "",                             //consignee City
                   "HKG",                        //Consignee Region
                   po.收件人郵遞區號,               //Consignee ZIP Code
                   po.連絡人,                      //Contact Person
                   po.收件連絡電話,                //連絡電話
                   "",                            //Personal ID / KTP
                   "",                            //Reference Number
                   "",                            //DOX/SPX
                   "",                            //CC/PP
                   "",                            //CC Amount
                   "",                         //Currency
             ];

             POs.push(poArray);
             var i;
             for(i=1;i<size;i++){

               let poArray = [
                 "",
                 po.快遞單號,
                 "",
                 "",
                 "",
                 "",
                 "",
                 po['內容物'][i].內容物,
                 po['內容物'][i].件數,
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 ""
               ];
               POs.push(poArray);
             }
           }

       }

       
       

     });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-HK上傳公司系統訂單.xlsx`);
    //把表命名做出來
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }


  ExportJPFile(PO){
    let POs = [];
      let title=[
          "跨境通提單號碼",              //A
          "訂單號碼",          //B
          "件數",                  //C
          "收件人",              //D
          "地址",           //E
          "電話",        //F
          "內容",               //G
          "重量",    //H
          "價值 NT$",            //I
          
          
        ];

       POs.push(title);

       
       var PoInfo=PO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    po.快遞單號,                 
                    po.訂單單號,                 
                    po.總箱數, 
                    po.收件人姓名,
                    po.收件地址,
                    po.收件連絡電話,
                    po.內容物,
                    Number(po['總重量-公斤']),                  
                    po['報關總值'],     
                                        
                   
                  ];
                  POs.push(poArray);
              }else{
                  
              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-JP2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }
  //下面這個是要把資料導出，丟公司系統
  ExportJPSystemFile(PO){
    //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
    let POs = [[
        "HAWB",                      //A
        "單號",                      //B
        "寄件人",                    //C
        "寄件地址",                  //D
        "收件人",                    //E
        "收件地址",                  //F
        "PKG",                      //G
        "內容物",                    //H
        "件數",                      //I
        "公斤數",                    //J
        "報關價值",                  //K
        "Remark",                   //L
        "consignee City",           //M
        "Consignee Region",         //N
        "Consignee ZIP Code",       //O
        "Contact Person",           //P
        "連絡電話",                  //Q
        "Personal ID / KTP",        //R
        "Reference Number",         //S
        "DOX/SPX",                  //T
        "CC/PP",                    //U
        "CC Amount",                //V
        "Currency"                  //W
       ]];
     
     
     var PoInfo=PO;

  
     Object.keys(PoInfo).forEach(function(key) {
       var po = PoInfo[key];

       if (typeof po.內容物 =='string'){
         let poArray = [
           '',                             //HAWB
           po.快遞單號,                     //單號
           po.寄件人姓名,                   //寄件人
           po.寄件地址,                     //寄件地址
           po.收件人姓名,                   //收件人
           po.收件地址,                     //收件地址
           "",                             //PKG
           po.內容物,                      //內容物
           po.總箱數,                      //件數
           Number(po['總重量-公斤']),               //公斤數
           po['報關總值'],         //報關價值
           "",                             //Remark
           "",                             //consignee City
           "NRT",                        //Consignee Region
           po.收件人郵遞區號,               //Consignee ZIP Code
           po.收件人姓名,                      //Contact Person
           po.收件連絡電話,                //連絡電話
           "",                            //Personal ID / KTP
           "",                            //Reference Number
           "SPX",                            //DOX/SPX
           "PP",                            //CC/PP
           "",                            //CC Amount
           "",                         //Currency
          ];
         //console.log(poArray);
         POs.push(poArray);

       }else{

             var size = Object.keys(po.內容物).length;
             if(size===1){
                 let poArray = [
                   '',                             //HAWB
                   po.快遞單號,                     //單號
                   po.寄件人姓名,                   //寄件人
                   po.寄件地址,                     //寄件地址
                   po.收件人姓名,                   //收件人
                   po.收件地址,                     //收件地址
                   "",                             //PKG
                   po['內容物'][0].內容物,          //內容物
                   po['內容物'][0].件數,            //件數
                   po['總重量-公斤'],               //公斤數
                   po['報關總值'],         //報關價值
                   "",                             //Remark
                   "",                             //consignee City
                   "NRT",                        //Consignee Region
                   po.收件人郵遞區號,               //Consignee ZIP Code
                   po.連絡人,                      //Contact Person
                   po.收件連絡電話,                //連絡電話
                   "",                            //Personal ID / KTP
                   "",                            //Reference Number
                   "",                            //DOX/SPX
                   "",                            //CC/PP
                   "",                            //CC Amount
                   "",                         //Currency
                 ];
                 //console.log(poArray);
                 POs.push(poArray);
           }else{
             let poArray = [
                   '',                             //HAWB
                   po.快遞單號,                     //單號
                   po.寄件人姓名,                   //寄件人
                   po.寄件地址,                     //寄件地址
                   po.收件人姓名,                   //收件人
                   po.收件地址,                     //收件地址
                   "",                             //PKG
                   po['內容物'][0].內容物,                      //內容物
                   po['內容物'][0].件數,                             //件數
                   po['總重量-公斤'],                      //公斤數
                   po['報關總值'],         //報關價值
                   "",                             //Remark
                   "",                             //consignee City
                   "NRT",                        //Consignee Region
                   po.收件人郵遞區號,               //Consignee ZIP Code
                   po.連絡人,                      //Contact Person
                   po.收件連絡電話,                //連絡電話
                   "",                            //Personal ID / KTP
                   "",                            //Reference Number
                   "",                            //DOX/SPX
                   "",                            //CC/PP
                   "",                            //CC Amount
                   "",                         //Currency
             ];

             POs.push(poArray);
             var i;
             for(i=1;i<size;i++){

               let poArray = [
                 "",
                 po.快遞單號,
                 "",
                 "",
                 "",
                 "",
                 "",
                 po['內容物'][i].內容物,
                 po['內容物'][i].件數,
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 ""
               ];
               POs.push(poArray);
             }
           }

       }

       
       

     });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-JP上傳公司系統訂單.xlsx`);
    //把表命名做出來
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }



  ExportAUFile(PO) {
    let POs = [];
    let title = [
      "跨境通提單號碼",              //A
      "訂單號碼",          //B
      "件數",                  //C
      "收件人",              //D
      "地址",           //E
      "電話",        //F
      "內容",               //G
      "重量",    //H
      "價值 NT$",            //I
      "城市",               //J
      "郵遞區號",           //K


    ];

    POs.push(title);


    var PoInfo = PO;
    Object.keys(PoInfo).forEach(function (key) {
      var po = PoInfo[key];
      //console.log(typeof po.內容物);

      if (typeof po.內容物 == 'string') {
        let poArray = [
          po.快遞單號,
          po.訂單單號,
          po.總箱數,
          po.收件人姓名,
          po.收件地址,
          po.收件連絡電話,
          po.內容物,
          Number(po['總重量-公斤']),
          po['報關總值'],
          po.addressCity,
          po.收件人郵遞區號,


        ];
        POs.push(poArray);
      } else {

      }

    });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day}-${this.state.customerID}-AU2訂單.xlsx`);

    //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }
  //下面這個是要把資料導出，丟公司系統
  ExportAUSystemFile(PO) {
    //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
    let POs = [[
      "HAWB",                      //A
      "單號",                      //B
      "寄件人",                    //C
      "寄件地址",                  //D
      "收件人",                    //E
      "收件地址",                  //F
      "PKG",                      //G
      "內容物",                    //H
      "件數",                      //I
      "公斤數",                    //J
      "報關價值",                  //K
      "Remark",                   //L
      "consignee City",           //M
      "Consignee Region",         //N
      "Consignee ZIP Code",       //O
      "Contact Person",           //P
      "連絡電話",                  //Q
      "Personal ID / KTP",        //R
      "Reference Number",         //S
      "DOX/SPX",                  //T
      "CC/PP",                    //U
      "CC Amount",                //V
      "Currency"                  //W
    ]];


    var PoInfo = PO;


    Object.keys(PoInfo).forEach(function (key) {
      var po = PoInfo[key];

      if (typeof po.內容物 == 'string') {
        let poArray = [
          '',                             //HAWB
          po.快遞單號,                     //單號
          po.寄件人姓名,                   //寄件人
          po.寄件地址,                     //寄件地址
          po.收件人姓名,                   //收件人
          po.收件地址,                     //收件地址
          "",                             //PKG
          po.內容物,                      //內容物
          po.總箱數,                      //件數
          Number(po['總重量-公斤']),               //公斤數
          po['報關總值'],         //報關價值
          "",                             //Remark
          "",                             //consignee City
          "SYD",                        //Consignee Region
          po.收件人郵遞區號,               //Consignee ZIP Code
          po.收件人姓名,                      //Contact Person
          po.收件連絡電話,                //連絡電話
          "",                            //Personal ID / KTP
          "",                            //Reference Number
          "SPX",                            //DOX/SPX
          "PP",                            //CC/PP
          "",                            //CC Amount
          "",                         //Currency
        ];
        //console.log(poArray);
        POs.push(poArray);

      } else {

        var size = Object.keys(po.內容物).length;
        if (size === 1) {
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po['內容物'][0].內容物,          //內容物
            po['內容物'][0].件數,            //件數
            po['總重量-公斤'],               //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "HKG",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.連絡人,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "",                            //DOX/SPX
            "",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];
          //console.log(poArray);
          POs.push(poArray);
        } else {
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po['內容物'][0].內容物,                      //內容物
            po['內容物'][0].件數,                             //件數
            po['總重量-公斤'],                      //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "HKG",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.連絡人,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "",                            //DOX/SPX
            "",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];

          POs.push(poArray);
          var i;
          for (i = 1; i < size; i++) {

            let poArray = [
              "",
              po.快遞單號,
              "",
              "",
              "",
              "",
              "",
              po['內容物'][i].內容物,
              po['內容物'][i].件數,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              ""
            ];
            POs.push(poArray);
          }
        }

      }




    });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day}-${this.state.customerID}-AU上傳公司系統訂單.xlsx`);
    //把表命名做出來
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }


  ExportKRFile(PO) {
    let POs = [];
    let title = [
      "跨境通提單號碼",              //A
      "訂單號碼",          //B
      "件數",                  //C
      "收件人",              //D
      "地址",           //E
      "電話",        //F
      "內容",               //G
      "重量",    //H
      "價值 NT$",            //I
      "城市",               //J
      "郵遞區號",           //K
      "ID"


    ];

    POs.push(title);


    var PoInfo = PO;
    Object.keys(PoInfo).forEach(function (key) {
      var po = PoInfo[key];
      //console.log(typeof po.內容物);

      if (typeof po.內容物 == 'string') {
        let poArray = [
          po.快遞單號,
          po.訂單單號,
          po.總箱數,
          po.收件人姓名,
          po.收件地址,
          po.收件連絡電話,
          po.內容物,
          Number(po['總重量-公斤']),
          po['報關總值'],
          po.addressCity,
          po.收件人郵遞區號,
          po.pcc


        ];
        POs.push(poArray);
      } else {

      }

    });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day}-${this.state.customerID}-KR2訂單.xlsx`);

    //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }
  //下面這個是要把資料導出，丟公司系統
  ExportKRSystemFile(PO) {
    //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
    let POs = [[
      "HAWB",                      //A
      "單號",                      //B
      "寄件人",                    //C
      "寄件地址",                  //D
      "收件人",                    //E
      "收件地址",                  //F
      "PKG",                      //G
      "內容物",                    //H
      "件數",                      //I
      "公斤數",                    //J
      "報關價值",                  //K
      "Remark",                   //L
      "consignee City",           //M
      "Consignee Region",         //N
      "Consignee ZIP Code",       //O
      "Contact Person",           //P
      "連絡電話",                  //Q
      "Personal ID / KTP",        //R
      "Reference Number",         //S
      "DOX/SPX",                  //T
      "CC/PP",                    //U
      "CC Amount",                //V
      "Currency"                  //W
    ]];


    var PoInfo = PO;


    Object.keys(PoInfo).forEach(function (key) {
      var po = PoInfo[key];

      if (typeof po.內容物 == 'string') {
        let poArray = [
          '',                             //HAWB
          po.快遞單號,                     //單號
          po.寄件人姓名,                   //寄件人
          po.寄件地址,                     //寄件地址
          po.收件人姓名,                   //收件人
          po.收件地址,                     //收件地址
          "",                             //PKG
          po.內容物,                      //內容物
          po.總箱數,                      //件數
          Number(po['總重量-公斤']),               //公斤數
          po['報關總值'],         //報關價值
          "",                             //Remark
          "",                             //consignee City
          "SYD",                        //Consignee Region
          po.收件人郵遞區號,               //Consignee ZIP Code
          po.收件人姓名,                      //Contact Person
          po.收件連絡電話,                //連絡電話
          "",                            //Personal ID / KTP
          "",                            //Reference Number
          "SPX",                            //DOX/SPX
          "PP",                            //CC/PP
          "",                            //CC Amount
          "",                         //Currency
        ];
        //console.log(poArray);
        POs.push(poArray);

      } else {

        var size = Object.keys(po.內容物).length;
        if (size === 1) {
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po['內容物'][0].內容物,          //內容物
            po['內容物'][0].件數,            //件數
            po['總重量-公斤'],               //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "HKG",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.連絡人,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "",                            //DOX/SPX
            "",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];
          //console.log(poArray);
          POs.push(poArray);
        } else {
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po['內容物'][0].內容物,                      //內容物
            po['內容物'][0].件數,                             //件數
            po['總重量-公斤'],                      //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "HKG",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.連絡人,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "",                            //DOX/SPX
            "",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];

          POs.push(poArray);
          var i;
          for (i = 1; i < size; i++) {

            let poArray = [
              "",
              po.快遞單號,
              "",
              "",
              "",
              "",
              "",
              po['內容物'][i].內容物,
              po['內容物'][i].件數,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              ""
            ];
            POs.push(poArray);
          }
        }

      }




    });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day}-${this.state.customerID}-KR上傳公司系統訂單.xlsx`);
    //把表命名做出來
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }

  ExportTHFile(PO) {
    let POs = [];
    let title = [
      "跨境通提單號碼",              //A
      "訂單號碼",          //B
      "件數",                  //C
      "收件人",              //D
      "地址",           //E
      "電話",        //F
      "內容",               //G
      "重量",    //H
      "價值 NT$",            //I
      "城市",               //J
      "郵遞區號",           //K


    ];

    POs.push(title);


    var PoInfo = PO;
    Object.keys(PoInfo).forEach(function (key) {
      var po = PoInfo[key];
      //console.log(typeof po.內容物);

      if (typeof po.內容物 == 'string') {
        let poArray = [
          po.快遞單號,
          po.訂單單號,
          po.總箱數,
          po.收件人姓名,
          po.收件地址,
          po.收件連絡電話,
          po.內容物,
          Number(po['總重量-公斤']),
          po['報關總值'],
          po.addressCity,
          po.收件人郵遞區號,


        ];
        POs.push(poArray);
      } else {

      }

    });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day}-${this.state.customerID}-TH2訂單.xlsx`);

    //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }
  //下面這個是要把資料導出，丟公司系統
  ExportTHSystemFile(PO) {
    //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
    let POs = [[
      "HAWB",                      //A
      "單號",                      //B
      "寄件人",                    //C
      "寄件地址",                  //D
      "收件人",                    //E
      "收件地址",                  //F
      "PKG",                      //G
      "內容物",                    //H
      "件數",                      //I
      "公斤數",                    //J
      "報關價值",                  //K
      "Remark",                   //L
      "consignee City",           //M
      "Consignee Region",         //N
      "Consignee ZIP Code",       //O
      "Contact Person",           //P
      "連絡電話",                  //Q
      "Personal ID / KTP",        //R
      "Reference Number",         //S
      "DOX/SPX",                  //T
      "CC/PP",                    //U
      "CC Amount",                //V
      "Currency"                  //W
    ]];


    var PoInfo = PO;


    Object.keys(PoInfo).forEach(function (key) {
      var po = PoInfo[key];

      if (typeof po.內容物 == 'string') {
        let poArray = [
          '',                             //HAWB
          po.快遞單號,                     //單號
          po.寄件人姓名,                   //寄件人
          po.寄件地址,                     //寄件地址
          po.收件人姓名,                   //收件人
          po.收件地址,                     //收件地址
          "",                             //PKG
          po.內容物,                      //內容物
          po.總箱數,                      //件數
          Number(po['總重量-公斤']),               //公斤數
          po['報關總值'],         //報關價值
          "",                             //Remark
          "",                             //consignee City
          "SYD",                        //Consignee Region
          po.收件人郵遞區號,               //Consignee ZIP Code
          po.收件人姓名,                      //Contact Person
          po.收件連絡電話,                //連絡電話
          "",                            //Personal ID / KTP
          "",                            //Reference Number
          "SPX",                            //DOX/SPX
          "PP",                            //CC/PP
          "",                            //CC Amount
          "",                         //Currency
        ];
        //console.log(poArray);
        POs.push(poArray);

      } else {

        var size = Object.keys(po.內容物).length;
        if (size === 1) {
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po['內容物'][0].內容物,          //內容物
            po['內容物'][0].件數,            //件數
            po['總重量-公斤'],               //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "HKG",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.連絡人,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "",                            //DOX/SPX
            "",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];
          //console.log(poArray);
          POs.push(poArray);
        } else {
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po['內容物'][0].內容物,                      //內容物
            po['內容物'][0].件數,                             //件數
            po['總重量-公斤'],                      //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "HKG",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.連絡人,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "",                            //DOX/SPX
            "",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];

          POs.push(poArray);
          var i;
          for (i = 1; i < size; i++) {

            let poArray = [
              "",
              po.快遞單號,
              "",
              "",
              "",
              "",
              "",
              po['內容物'][i].內容物,
              po['內容物'][i].件數,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              ""
            ];
            POs.push(poArray);
          }
        }

      }




    });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day}-${this.state.customerID}-TH上傳公司系統訂單.xlsx`);
    //把表命名做出來
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }


  ExportUSFile(PO) {
    let POs = [];
    let title = [
      "跨境通提單號碼",              //A
      "訂單號碼",          //B
      "件數",                  //C
      "收件人",              //D
      "地址",           //E
      "電話",        //F
      "內容",               //G
      "重量",    //H
      "價值 NT$",            //I
      "城市",               //J
      "郵遞區號",           //K


    ];

    POs.push(title);


    var PoInfo = PO;
    Object.keys(PoInfo).forEach(function (key) {
      var po = PoInfo[key];
      //console.log(typeof po.內容物);

      if (typeof po.內容物 == 'string') {
        let poArray = [
          po.快遞單號,
          po.訂單單號,
          po.總箱數,
          po.收件人姓名,
          po.收件地址,
          po.收件連絡電話,
          po.內容物,
          Number(po['總重量-公斤']),
          po['報關總值'],
          po.addressCity,
          po.收件人郵遞區號,


        ];
        POs.push(poArray);
      } else {

      }

    });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day}-${this.state.customerID}-US2訂單.xlsx`);

    //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }
  //下面這個是要把資料導出，丟公司系統
  ExportUSSystemFile(PO) {
    //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
    let POs = [[
      "HAWB",                      //A
      "單號",                      //B
      "寄件人",                    //C
      "寄件地址",                  //D
      "收件人",                    //E
      "收件地址",                  //F
      "PKG",                      //G
      "內容物",                    //H
      "件數",                      //I
      "公斤數",                    //J
      "報關價值",                  //K
      "Remark",                   //L
      "consignee City",           //M
      "Consignee Region",         //N
      "Consignee ZIP Code",       //O
      "Contact Person",           //P
      "連絡電話",                  //Q
      "Personal ID / KTP",        //R
      "Reference Number",         //S
      "DOX/SPX",                  //T
      "CC/PP",                    //U
      "CC Amount",                //V
      "Currency"                  //W
    ]];


    var PoInfo = PO;


    Object.keys(PoInfo).forEach(function (key) {
      var po = PoInfo[key];

      if (typeof po.內容物 == 'string') {
        let poArray = [
          '',                             //HAWB
          po.快遞單號,                     //單號
          po.寄件人姓名,                   //寄件人
          po.寄件地址,                     //寄件地址
          po.收件人姓名,                   //收件人
          po.收件地址,                     //收件地址
          "",                             //PKG
          po.內容物,                      //內容物
          po.總箱數,                      //件數
          Number(po['總重量-公斤']),               //公斤數
          po['報關總值'],         //報關價值
          "",                             //Remark
          "",                             //consignee City
          "SYD",                        //Consignee Region
          po.收件人郵遞區號,               //Consignee ZIP Code
          po.收件人姓名,                      //Contact Person
          po.收件連絡電話,                //連絡電話
          "",                            //Personal ID / KTP
          "",                            //Reference Number
          "SPX",                            //DOX/SPX
          "PP",                            //CC/PP
          "",                            //CC Amount
          "",                         //Currency
        ];
        //console.log(poArray);
        POs.push(poArray);

      } else {

        var size = Object.keys(po.內容物).length;
        if (size === 1) {
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po['內容物'][0].內容物,          //內容物
            po['內容物'][0].件數,            //件數
            po['總重量-公斤'],               //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "HKG",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.連絡人,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "",                            //DOX/SPX
            "",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];
          //console.log(poArray);
          POs.push(poArray);
        } else {
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po['內容物'][0].內容物,                      //內容物
            po['內容物'][0].件數,                             //件數
            po['總重量-公斤'],                      //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "HKG",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.連絡人,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "",                            //DOX/SPX
            "",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];

          POs.push(poArray);
          var i;
          for (i = 1; i < size; i++) {

            let poArray = [
              "",
              po.快遞單號,
              "",
              "",
              "",
              "",
              "",
              po['內容物'][i].內容物,
              po['內容物'][i].件數,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              ""
            ];
            POs.push(poArray);
          }
        }

      }




    });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.year + this.state.month + this.state.day}-${this.state.customerID}-US上傳公司系統訂單.xlsx`);
    //把表命名做出來
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }
  
 

  //下面的程式結構是可以直接呼叫this.state的
  superItemCalculation=()=>{
    var nation=this.state.deliveryNation
    var target='itemCalculation'+nation+'File'
    this[target]()
  }

  superExportFile=()=>{
    
    ref.child('/infos/customers/'+this.state.customerID+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation).once('value', (snapshot)=> {
      var PO=snapshot.val()
      
      
      var keys=Object.keys(PO)
      for(let i=0; i<keys.length;i++) {
        
        if (keys[i].length===28){
    
          PO=PO[keys[i]]
          // console.log(PO)
        }
      } 
      
      var target='Export'+this.state.deliveryNation+'File'
      this[target](PO)
    })
  }

  superExportSystemFile=()=>{
    ref.child('/infos/customers/'+this.state.customerID+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation).once('value', (snapshot)=> {
      var PO=snapshot.val()
      var keys=Object.keys(PO)
      for(let i=0; i<keys.length;i++) {
        
        if (keys[i].length===28){
          PO=PO[keys[i]]
        }
      } 
      var target='Export'+this.state.deliveryNation+'SystemFile'
      this[target](PO)
    })

  }

  ExportQIIFile=()=>{
    //先把表頭建立起來
    let POs = [];
    let title=[
        "賣方ID",               //A
        "發件人名稱",            //B
        "出發國家",              //C
        "國家",                  //D
        "參考號碼",              //E
        "商品名稱",              //F
        "Qty of Items",         //G
        "包裹金額（總計）",       //H
        "名稱",                  //I
        "郵遞區號",              //J
        "Recipient Address 1",  //K
        "Recipient Address 2",  //L
        "移動",                  //M
        "電話",                  //N
        "電子郵件",              //O
        "貨幣",                  //P
        "備註",                  //Q
        "Use Fukuoka Route",     //R
        "服務</br>等級",          //S
        "名（平假名日語）",        //T
        "Premium Service",        //U
        "出生日期",                //V
        "Purchase customer clearance number",             //W
        "商品網址",                                       //X
        "項目明細分類",                                   //Y
        "用戶定義的商品代碼",                             //Z
        "Use Cod Service",                              //AA
        "COD Value",                                   //AB
        "COD Value Currency",                          //AC
            
      ];

    POs.push(title);

    if(this.state.deliveryNation === 'SG'){
      ref.child('/infos/customers/'+this.state.customerID+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/SG').on('value', (snapshot)=> {
        var PO=snapshot.val()
        var keys=Object.keys(PO)
        PO=PO[keys[0]]

         //因為下面碼已經用了PoInfo，所以對接就好，不改了
         var PoInfo=PO;
         
         Object.keys(PoInfo).forEach(function(key) {
               var po = PoInfo[key];
               let itemNumber=po['itemNumber']
               
               for (var i = 1; i < itemNumber+1; i++) {
                      let target=i
                      //算單品的價格
                      let amount=0
                      if( po['itemAmount'+target]){
                          amount= Number((po['itemAmount'+target]/22).toFixed(1))
                      }
                      

                      let poArray = [
                        po.寄件人姓名,                                 //"賣方ID",               //A
                        '',                                             //"發件人名稱",            //B
                        'TW',                                           //"出發國家",              //C
                        po.國別,                                         //"國家",                  //D
                        po.快遞單號,                                               //"參考號碼",              //E
                        po['itemName'+target],                                                //"商品名稱",              //F
                        po['itemNumber'+target],                          //"Qty of Items",         //G
                        amount,                                          //"包裹金額（總計）",       //H
                        po.收件人姓名,                                   //"名稱",                  //I
                        po.收件人郵遞區號,                                                //"郵遞區號",              //J
                        po.收件地址,                                     //"Recipient Address 1",  //K
                        po.收件連絡電話,                                                //"Recipient Address 2",  //L
                        po.收件連絡電話,                                 //"移動",                  //M
                        '',                                                //"電話",                  //N
                        '',                                                //"電子郵件",              //O
                        'SGD',                                                //"貨幣",                  //P
                        '',                                                //"備註",                  //Q
                        '',                                                //"Use Fukuoka Route",     //R
                        '',                                                //"服務</br>等級 之前預設STD",          //S
                        '',                                                //"名（平假名日語）",        //T
                        '',                                                //"Premium Service",        //U
                        '',                                                //"出生日期",                //V
                        '',                                                //"Purchase customer clearance number",             //W
                        '',                                                //"商品網址",                                       //X
                        '',                                                //"項目明細分類",                                   //Y
                        '',                                                //"用戶定義的商品代碼",                             //Z
                        '',                                                //"Use Cod Service",                              //AA
                        '',                                                //"COD Value",                                   //AB
                        '',                                                //"COD Value Currency",                          //AC
                      ];
                    
                      POs.push(poArray);
               }
               
      
       });
  
        //創造一張新的表
        const wb = XLSX.utils.book_new();
        //下面這句就是把POs放進我們的sheet裡面
        const wsAll = XLSX.utils.aoa_to_sheet(POs);
        //把Sheet放進我們的Excel檔案裡面
        XLSX.utils.book_append_sheet(wb, wsAll, "All POs");
  
        //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
        //利用saveAs就可以把這個檔案存出來
        saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-${this.state.deliveryNation}-QII上傳表格.xlsx`);
      })

    }else if (this.state.deliveryNation === 'JP') {
      ref.child('/infos/customers/'+this.state.customerID+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/JP').on('value', (snapshot)=> {
        var PO=snapshot.val()
        var keys=Object.keys(PO)
        PO=PO[keys[0]]
        
        
  
         //因為下面碼已經用了PoInfo，所以對接就好，不改了
         var PoInfo=PO;
         
         Object.keys(PoInfo).forEach(function(key) {
               var po = PoInfo[key];
               let itemNumber=po['itemNumber']
               let amount= Number((FloatDiv(po['報關總值'],0.285)).toFixed(1))
               let content=''
               let totalPCs=0
               for (var i = 0; i < itemNumber; i++) {
                  if(i === itemNumber-1){
                      let target=i+1
                      content=content.concat(po['itemName'+target])
                      totalPCs=totalPCs+Number(po['itemNumber'+target])

                  }else{
                      let target=i+1
                      content=content.concat(po['itemName'+target],',')
                      totalPCs=totalPCs+Number(po['itemNumber'+target])

                  }
                      
               }
               //20210124  如果普世特正常，就可以刪除
               //let address=po.收件地址.split(" ")
               //let postCode=address[address.length-2]

               let poArray = [
                po.寄件人姓名,                                 //"賣方ID",               //A
                'QXPRESS TAIWAN',                                             //"發件人名稱",            //B
                'TW',                                           //"出發國家",              //C
                po.國別,                      //"國家",                  //D
                po.快遞單號,                                               //"參考號碼",              //E
                content,                                                //"商品名稱",              //F
                totalPCs,                          //"Qty of Items",         //G
                amount,                                  //"包裹金額（總計）",       //H
                po.收件人姓名,                                   //"名稱",                  //I
                po.收件人郵遞區號,                                                //"郵遞區號",              //J
                po.收件地址,                                     //"Recipient Address 1",  //K
                po.doorNumber,                                                //"Recipient Address 2",  //L
                po.收件連絡電話,                                 //"移動",                  //M
                '',                                                //"電話",                  //N
                '',                                                //"電子郵件",              //O
                'JPY',                                                //"貨幣",                  //P
                '',                                                //"備註",                  //Q
                '',                                                //"Use Fukuoka Route",     //R
                'STD',                                                //"服務</br>等級",          //S
                po.contactENG,                                                //"名（平假名日語）",        //T
                '',                                                //"Premium Service",        //U
                '',                                                //"出生日期",                //V
                '',                                                //"Purchase customer clearance number",             //W
                '',                                                //"商品網址",                                       //X
                '',                                                //"項目明細分類",                                   //Y
                '',                                                //"用戶定義的商品代碼",                             //Z
                '',                                                //"Use Cod Service",                              //AA
                '',                                                //"COD Value",                                   //AB
                '',                                                //"COD Value Currency",                          //AC
              ];
            
              POs.push(poArray);
               
      
       });
  
        //創造一張新的表
        const wb = XLSX.utils.book_new();
        //下面這句就是把POs放進我們的sheet裡面
        const wsAll = XLSX.utils.aoa_to_sheet(POs);
        //把Sheet放進我們的Excel檔案裡面
        XLSX.utils.book_append_sheet(wb, wsAll, "All POs");
  
        //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
        //利用saveAs就可以把這個檔案存出來
        saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-${this.state.deliveryNation}-QII上傳表格.xlsx`);
      })
    }
    
    
  }

  ExportHubFile=()=>{
    //先把表頭建立起來
    let POs = [];
    let title=[
        "跨境通提單號碼",       //A
        "訂單號碼",            //B
        "件數",               //C
        "收件人",             //D
        "地址",              //E
        "電話",              //F
        "內容",              //G
        "重量",              //H
        "價值 NT$",          //I
        "統編",              //J            
      ];

    POs.push(title);

    
    ref.child('/infos/customers/'+this.state.customerID+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/HK').on('value', (snapshot)=> {
      if(snapshot.val()==null){
          
      }else{
        var data=snapshot.val()

        var keys=Object.keys(data)
        var PO=data[keys[0]]
        var nonUpLoad=data['nonUpLoad']
         
        Object.keys(nonUpLoad).forEach(function(key) {
               var target = PO[key];
               let poArray =[
                      target.快遞單號,       //A
                      target.快遞單號,            //B
                      1,               //C
                      target.收件人姓名,             //D
                      target.收件地址,              //E
                      target.收件連絡電話,              //F
                      '包',              //G
                      target['總重量-公斤'],              //H
                      target.報關總值,          //I
                      " ",              //J            
              ];
             
                    
              POs.push(poArray);
               
               
      
            });
        }
  
        //創造一張新的表
        const wb = XLSX.utils.book_new();
        //下面這句就是把POs放進我們的sheet裡面
        const wsAll = XLSX.utils.aoa_to_sheet(POs);
        //把Sheet放進我們的Excel檔案裡面
        XLSX.utils.book_append_sheet(wb, wsAll, "DATA");
  
        //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
        //利用saveAs就可以把這個檔案存出來
        saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-${this.state.customerID}-${this.state.deliveryNation}-Hubez上傳表格.xlsx`);
      })

    
    
    
  }

  UniversalImportExcel= file => {
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据

        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break; // 如果除了取第一张表以外，還要其他的表，就取消注释这行
          }
        };

        //設定國別
        let nation='MIX'
        let goodData=[];

        
  
        data.forEach((element)=> {                      
                  //系統紀錄單號一律用客戶的訂單號碼，單號唯一，但是快遞單號可以改變
                  let number=element.跨境通提單號碼;
                  let date = new Date();
                                
                  goodData[number]=[];
                  goodData[number].印製張數=element.印製張數;
                  goodData[number].快遞單號=element.跨境通提單號碼;;
                  
                  if(element['訂單單號']){
                    goodData[number].訂單單號=element['訂單單號'];
                  }else{
                    goodData[number].訂單單號='';
                  }

                  if(element['AC-NO']){
                    goodData[number].acno=element['AC-NO'];
                  }else{
                    goodData[number].acno='';
                  }

                  if(element['AC-NO'] && element.國別==='SG'){
                    goodData[number].收件人郵遞區號=element['AC-NO']
                  }else{
                    goodData[number].收件人郵遞區號=''
                  }
                  

                  

                  //date一定要做這個處理，要不然進不了realtime database
                  goodData[number].日期=date.toDateString();                            
                  goodData[number].寄件人姓名=element.寄件人姓名;

                  if(element['寄件人連絡電話']){
                    goodData[number].寄件人連絡電話=element['寄件人連絡電話'];
                  }else{
                    goodData[number].寄件人連絡電話='';
                  }

                  if(element['寄件人地址']){
                    goodData[number].寄件地址=element['寄件人地址'];
                  }else{
                    goodData[number].寄件地址='';
                  }


                                              
                  //goodData[number].寄件地址="";
                  goodData[number].dataURL=textToBase64Barcode(goodData[number].快遞單號);

                  goodData[number].收件人姓名=element.收件人姓名;
                  
                  goodData[number].收件地址=element.收件人地址;
                  goodData[number].收件連絡電話=element.收件人連絡電話;

                  goodData[number].國別=element.國別;

                  goodData[number].內容物=element.內容物;
                  
                  goodData[number].總箱數=element.件數;

                  goodData[number]['總重量-公斤']=element.重量;
                  
                  goodData[number]['報關總值']=element.總值;
                  
                 
                  goodData[number].內容物=element.內容物
                  

      });
           console.log(goodData)
           
           //要把國別記錄下來，這樣後面韓國、越南都可以好分類
           this.setState({ MIXPO: goodData,  MIXUpload:'success'});
           

      } catch (e) {
        this.setState({
          MIXUpload:'檔案格式有問題'
        });
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  handleUniversalLablePrint(){
    //先把我們要處理的資料倒進來，所以隨著訂單不同，要改 
    //這是通用universal的版本，所以印製張數看表格
    /*
    要加印
    [{ text: 'A/C NO:'},
    {text: 'N6900038',
      colSpan: 5},
    */
   
    let goodData=this.state['MIXPO'];
    //let Nation='MY';
    //let Currency='MYR';
    
    
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    

    //把印製的Label格式準備好
    var LabelArr = [];

    //把最後一張訂單挑出來，因為這張訂單要特別處理，不要接下一頁
    const keys = Object.keys(goodData);
    var last=keys.pop();

    

    //開始處理每張訂單，導進標籤印製群組
    Object.keys(goodData).forEach((key)=> {
      var po=goodData[key];
      
      //logo是跨境通的logo，在這裡先導進來
      var logo=LogoImg();

      //下面這句是避免原廠導入格式不乾淨，全部轉成Number後面好處理
      var LabelNumber=Number(po.印製張數);
      
      
      
        //馬來西亞的標籤數需求就是總箱數再加1張，就這麼簡單
          for(var i=0;i<LabelNumber;i++){
            //如果是最後一張單的最後一張標，就不要再下一頁了，這是唯一需要特別處理的。
            if(key===last && i===LabelNumber-1){         
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
                    //這是馬來西亞標籤特別要加的
                    [{ text: 'A/C NO:'},
                    {text: po.acno,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper TEL:'},
                    {text: po.寄件人連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper Address:'},
                    {text: po.寄件地址,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Package:'},
                    {text: po['總箱數'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [
                    //   {text: 'Declared Value:'},
                    // {text: po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    //KGS前面後一格，看起來比較好看
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: '',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ]
                }},     
              ];

              LabelArr.push(Label);

            }else{
             //一般情況下都是跑下面這張標籤        
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
                    //這是馬來西亞標籤特別要加的
                    [{ text: 'A/C NO:'},
                    {text: po.acno,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper TEL:'},
                    {text: po.寄件人連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper Address:'},
                    {text: po.寄件地址,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Package:'},
                    {text: po['總箱數'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [
                    //   {text: 'Declared Value:'},
                    // {text: po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    //KGS前面後一格，看起來比較好看
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: '',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ],
                
                  },
                  pageBreak: 'after'
                }, 
              
              ];

              LabelArr.push(Label);
            };
          };

    });

    
    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
     }};

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 8
        } ,
        styles: {
          head: {
            
            fontSize: 66,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        // 下面是10*10的標籤設計
        pageSize: { width: 283, height: 283},
        

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3,3, 0 ],
      };
     
      let labeltitle="跨境通標籤-"+this.state.nation+this.state.todayTitle+'.pdf';
      
      

      pdfmake.createPdf(docDefinition).download(labeltitle);
           //pdfmake.createPdf(docDefinition).open();
      

      
      

  }

 




  render() {
    return (


      <div>
        <div style={{ padding: '1em 2em','font-size':'26px','font-weight':'bold','background-color': 'rgb(244, 173, 66)'}}>
          <Header as='h1'>歡迎來到特殊功能區。</Header>
          <Grid  divided='vertically' centered >
              <Grid.Row columns={1}>  
                  <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                  
                    <Segment raised>  
                          <Label as='a' color='red' ribbon>
                           下載報關數量
                          </Label>
                          
                          <Form style={{'font-size':'18px'}}>
                              <Form.Group widths='equal' id='shipperForm' style={{ height: '2em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'16px'}}>國家別</label>
                        
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                      <Form.Field
                                          control={Radio}
                                          label='香港'
                                          value='HK'
                                          checked={this.state.deliveryNation=== 'HK'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='馬來西亞'
                                          value='MY'
                                          checked={this.state.deliveryNation=== 'MY'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='新加坡'
                                          value='SG'
                                          checked={this.state.deliveryNation=== 'SG'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='澳門'
                                          value='MO'
                                          checked={this.state.deliveryNation=== 'MO'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      
                                      <Form.Field
                                          control={Radio}
                                          label='日本'
                                          value='JP'
                                          checked={this.state.deliveryNation=== 'JP'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />

                                      <Form.Field
                                        control={Radio}
                                        label='澳洲'
                                        value='AU'
                                        checked={this.state.deliveryNation === 'AU'}
                                        onChange={this.changeDeliveryNation}
                                        style={{ 'font-size': '18px' }}
                                      />

                                      <Form.Field
                                        control={Radio}
                                        label='韓國'
                                        value='KR'
                                        checked={this.state.deliveryNation === 'KR'}
                                        onChange={this.changeDeliveryNation}
                                        style={{ 'font-size': '18px' }}
                                      />

                                      <Form.Field
                                        control={Radio}
                                        label='泰國'
                                        value='TH'
                                        checked={this.state.deliveryNation === 'TH'}
                                        onChange={this.changeDeliveryNation}
                                        style={{ 'font-size': '18px' }}
                                      />

                                      <Form.Field
                                        control={Radio}
                                        label='美國'
                                        value='US'
                                        checked={this.state.deliveryNation === 'US'}
                                        onChange={this.changeDeliveryNation}
                                        style={{ 'font-size': '18px' }}
                                      />
                                      
                                      </Form.Group>
                                  </Form.Field> 
                              </Form.Group>
                              <Form.Group widths='equal'  style={{ height: '10em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'16px'}}>客戶代碼</label>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <input style={{'font-size':'18px', height: '2em',width:'350px','font-weight':'bold',padding: '0.3em' }}  placeholder='請輸入客戶代碼' name="customerNumber" onChange={this.handleInputChange} />
                                          
                                      </Form.Group>
                                      <label style={{'font-size':'16px'}}>日期八碼</label>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <input style={{'font-size':'18px', height: '2em',width:'350px','font-weight':'bold',padding: '0.3em' }}  placeholder='請輸入日期八碼' name="time" onChange={this.handleTimeChange} />
                                          
                                      </Form.Group>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <Button icon='print' label='報關數量'  size='mini' color='red' onClick={this.superItemCalculation} style={{ 'font-size':'18px','margin-right': '2em'}}/> 
                                          
                                      </Form.Group>
                                  </Form.Field> 
                              </Form.Group>
                             
                          </Form>
                    
                    </Segment>
                 
              </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={1}>  
                  <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                  
                    <Segment raised>  
                          <Label as='a' color='red' ribbon>
                           下載日本相關文件
                          </Label>
                          
                          <Form style={{'font-size':'18px'}}>
                              <Form.Group widths='equal'  style={{ height: '10em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'16px'}}>客戶代號</label>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <input style={{'font-size':'18px', height: '2em',width:'350px','font-weight':'bold',padding: '0.3em' }}  placeholder='請輸入客戶代號' name="customerID" onChange={this.handleInputChange} /> 
                                      </Form.Group>

                                      <label style={{'font-size':'16px'}}>日期八碼</label>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <input style={{'font-size':'18px', height: '2em',width:'350px','font-weight':'bold',padding: '0.3em' }}  placeholder='請輸入日期八碼' name="time" onChange={this.handleTimeChange} />
                                          
                                      </Form.Group>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <Button icon='print' label='日本發票'  size='mini' color='green' onClick={this.ExportJPInvoice} style={{ 'font-size':'18px','margin-right': '2em'}}/> 
                                          <Button icon='print' label='日本提單'  size='mini' color='olive' onClick={this.ExportJPLabelA4} style={{ 'font-size':'18px',}}/> 
                                      </Form.Group>
                                  </Form.Field> 
                              </Form.Group>
                             
                          </Form>
                    
                    </Segment>
                 
              </Grid.Column>
              </Grid.Row>

            

              <Grid.Row columns={1}>  
                  <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                  
                    <Segment raised>  
                          <Label as='a' color='red' ribbon>
                           下載客戶歷史報表和標籤
                          </Label>
                          
                          <Form style={{'font-size':'18px'}}>
                             <Form.Group widths='equal'  style={{ height: '3em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'16px'}}>客戶代號</label>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <input style={{'font-size':'18px', height: '2em',width:'350px','font-weight':'bold',padding: '0.3em' }}  placeholder='請輸入客戶代號' name="customerID" onChange={this.handleInputChange} /> 
                                      </Form.Group>
                                      
                                  </Form.Field> 
                              </Form.Group>
                              <Form.Group widths='equal' id='shipperForm' style={{ height: '2em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'16px'}}>國家別</label>
                        
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                      <Form.Field
                                          control={Radio}
                                          label='香港'
                                          value='HK'
                                          checked={this.state.deliveryNation=== 'HK'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='馬來西亞'
                                          value='MY'
                                          checked={this.state.deliveryNation=== 'MY'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='新加坡'
                                          value='SG'
                                          checked={this.state.deliveryNation=== 'SG'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='澳門'
                                          value='MO'
                                          checked={this.state.deliveryNation=== 'MO'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      
                                      <Form.Field
                                          control={Radio}
                                          label='日本'
                                          value='JP'
                                          checked={this.state.deliveryNation=== 'JP'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />

                                      <Form.Field
                                        control={Radio}
                                        label='澳洲'
                                        value='AU'
                                        checked={this.state.deliveryNation === 'AU'}
                                        onChange={this.changeDeliveryNation}
                                        style={{ 'font-size': '18px' }}
                                      />

                                      <Form.Field
                                        control={Radio}
                                        label='韓國'
                                        value='KR'
                                        checked={this.state.deliveryNation === 'KR'}
                                        onChange={this.changeDeliveryNation}
                                        style={{ 'font-size': '18px' }}
                                      />

                                      <Form.Field
                                        control={Radio}
                                        label='泰國'
                                        value='TH'
                                        checked={this.state.deliveryNation === 'TH'}
                                        onChange={this.changeDeliveryNation}
                                        style={{ 'font-size': '18px' }}
                                      />

                                      <Form.Field
                                        control={Radio}
                                        label='美國'
                                        value='US'
                                        checked={this.state.deliveryNation === 'US'}
                                        onChange={this.changeDeliveryNation}
                                        style={{ 'font-size': '18px' }}
                                      />
                                      
                                      </Form.Group>
                                  </Form.Field> 
                              </Form.Group>
                              <Form.Group widths='equal'  style={{ height: '5em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'16px'}}>日期八碼</label>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <input style={{'font-size':'18px', height: '2em',width:'350px','font-weight':'bold',padding: '0.3em' }}  placeholder='請輸入日期八碼' name="time" onChange={this.handleTimeChange} />
                                          
                                      </Form.Group>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <Button icon='print' label='下載公司系統需求報表'  size='mini' color='green' onClick={this.superExportSystemFile} style={{ 'font-size':'18px','margin-right': '2em'}}/> 
                                          <Button icon='print' label='下載local端需求報表'  size='mini' color='olive' onClick={this.superExportFile} style={{ 'font-size':'18px','margin-right': '2em'}}/> 
                                          <Button icon='print' label='下載QII端需求報表'  size='mini' color='pink' onClick={this.ExportQIIFile} style={{ 'font-size':'18px','margin-right': '2em'}}/>
                                          <Button icon='print' label='下載Hubez上傳失敗報表'  size='mini' color='pink' onClick={this.ExportHubFile} style={{ 'font-size':'18px','margin-right': '2em'}}/>
                                      </Form.Group>
                                  </Form.Field> 
                              </Form.Group>
                             
                          </Form>
                    
                    </Segment>
                 
              </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={1}>  
                  <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                  
                    <Segment raised>  
                          <Label as='a' color='red' ribbon>
                           印製提單
                          </Label>
                         
                          <Form style={{'font-size':'18px'}}>
                              <Form.Group widths='equal'  style={{ height: '6em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'16px'}}>請先上傳萬用格式訂單寄送資料</label>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                            <Input style={{'font-size':'18px', height: '3em',width:'350px','font-weight':'bold',padding: '0.3em' }} type='file'  accept='.xlsx, .xls' onChange={this.UniversalImportExcel} />
                                            {this.state.MIXUpload? <Form.Group style={{'padding-left': '1em', 'padding-top': '1em'}} > 上傳成功 </Form.Group> :''}
                                          
                                      </Form.Group>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                             <Button icon='print' label='印製標籤'  size='mini' color='pink' onClick={this.handleUniversalLablePrint} style={{ 'font-size':'18px','margin-right': '2em'}}/> 
                                      </Form.Group>
                                  </Form.Field> 
                              </Form.Group>
                             
                          </Form>
                    
                    </Segment>
                 
              </Grid.Column>
              </Grid.Row> 

              <Grid.Row columns={1}>  
                    <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                    
                      <Segment raised>  
                            <Label as='a' color='red' ribbon>
                             普仕特日本快遞單號添加區
                            </Label>
                            
                            <Form style={{'font-size':'20px'}}>
                                <Form.Group widths='equal' id='shipperForm' style={{ height: '4em'}}> 
                                    <Form.Field>
                                        <label style={{'font-size':'18px'}}>請輸入快遞單號的開始單號和結束單號</label>
                          
                                        <Form.Group widths='equal'  style={{ height: '2.5em'}}> 
                                          <Form.Field>
                                              <label style={{'font-size':'18px'}}>開始單號</label>
                                              <input   placeholder='單號' value={this.state.deliveryNumberStart} name="deliveryNumberStart" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                          </Form.Field> 
                                          <Form.Field>
                                              <label style={{'font-size':'18px'}}>結束單號</label>
                                              <input   placeholder='單號' value={this.state.deliveryNumberStop} name="deliveryNumberStop" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                          </Form.Field> 
                                          <Form.Field> 
                                              <label style={{'font-size':'18px'}}>剩餘{this.state.JPDeliveryNumber}張</label>   
                                              <Button icon='print' label='輸入資料庫'  size='mini' color='red' onClick={this.addToDatabase} style={{ 'font-size':'16px'}}/>
                                          </Form.Field>
                                          
                                      </Form.Group>
                                    </Form.Field> 
                                </Form.Group>
                              
                                
                            </Form>
                      
                      </Segment>
                  
                </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>  
                  <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                  
                    <Segment raised>  
                          <Label as='a' color='red' ribbon>
                           下載80071刪單紀錄
                          </Label>
                          
                          <Form style={{'font-size':'18px'}}>
                              <Form.Group widths='equal'  style={{ height: '5em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'16px'}}>日期八碼</label>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <input style={{'font-size':'18px', height: '2em',width:'350px','font-weight':'bold',padding: '0.3em' }}  placeholder='請輸入日期八碼' name="time" onChange={this.handleTimeChange} />
                                          
                                      </Form.Group>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                          <Button icon='print' label='下載普仕特刪單紀錄'  size='mini' color='pink' onClick={this.ExportDeleteRecord} style={{ 'font-size':'18px','margin-right': '2em'}}/> 
                                          
                                      </Form.Group>
                                  </Form.Field> 
                              </Form.Group>
                             
                          </Form>
                    
                    </Segment>
                 
              </Grid.Column>
              </Grid.Row>

            </Grid>

        </div>

        
      </div>
        




    
    )
  }
}

export default App;
